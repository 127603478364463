import $ from 'jquery';

var API_BASE_URL='https://api.worldwidetechconnections.com/api/';

var credentials={
    authorization:'Basic  1234567890',
}

var TOKEN = null;

function getToken(){
    var myHeaders = new Headers({Authorization:credentials.authorization});
    var url=API_BASE_URL+'session';
    //myHeaders.append('', credentials.authorization);
    fetch(url,
        {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
        }
    )
    .then((response) => {
        //console.error('response',response);
        return response.json();
    })
    .then((data) => {
        //console.error(data.token);
        TOKEN=data.token;
    })
    .catch((error)=>{
        console.error('https://api.worldwidetechconnections.com/api',error);
    });
}

var autodetectionCredentials={
    vendor: "google",
}

var detectlanguage = (audio, callback) => {
    var form = new FormData();
	form.append("file", audio, "ORIGINAL.wav");
    
    //console.error('detectando lenguage del audio:', audio);   

    const settings = {
        "async": true,
        "crossDomain": true,
        "url": API_BASE_URL+'SpeechToText?token='+TOKEN+'&vendor='+autodetectionCredentials.vendor+'&sourceLanguage=*',
        "method": "POST",
        "headers": {
            "authorization": credentials.authorization,
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form,
        success: function(response) {
            // handle response
            //console.error('response', JSON.parse(response));
            callback(JSON.parse(response), true);
        },
        error: function (xhr, ajaxOptions, thrownError) {
            // handle error
            //console.error('error');
            callback(thrownError, false);
        }
    }
    $.ajax(settings);
}

getToken();

export { detectlanguage }; 
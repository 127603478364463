import React,{useState} from 'react';

import './styles.scss';
import DetectLanguage from '../detectLanguage';

const Alert = (props) => {

    const [state, setState] = useState({visible:true});

    const handleClose = () => {
        setState({...state, visible:false});
    }
    
    /*const handleModal = () => {
        setState({...state, visibleModal: !state.visibleModal});
    }*/

    return (
        <div className='alert-container' style={{...props.style, display:((state.visible===true)?'':'none')}}>
            <span className='close-btn' onClick={ ()=>{ handleClose() } }>
                <i className="fas fa-times-circle"></i>
            </span>
            <span>{props.primaryText}</span>
            <DetectLanguage
                text={ props.buttonText }
                startAutoDetection = { props.startAutoDetection }
                stopAutoDetection={ props.stopAutoDetection }
                callStatus={ props.callStatus }
                upDateTranslationParameters={ props.upDateTranslationParameters }
                setLocalLanguage={props.setLocalLanguage}
                setRemoteLanguage={props.setRemoteLanguage}
                url={'detect-language'}
            />
        </div>
    )
}
export default Alert;
import React,{Component} from 'react';

import './styles.scss';
import logo from '../../img/svg/LogoWWTC_Forms-01.svg';
import Alert from '../alert';
import Button from '../button';

import DropDownMenu from '../dropDownMenu';

export default class extends Component{

    state = {
        translation: false,
        messages: 0,
    }

    constructor(props){
        super(props);
        this.exampleRef = React.createRef();
    }

    targetNode = null;

    // Options for the observer (which mutations to observe)
    config = { 
        attributes: true, 
        childList: true, 
        subtree: true,
    };

    handle(){
        //console.error(this.props.translationParameters);
    }

    // Callback function to execute when mutations are observed
    callback = (mutationsList, observer) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                //console.log('A child node has been added or removed in the chat');

                //console.error(mutation.addedNodes.length)

                let count = mutation.addedNodes.length;
                this.setState({messages:count});
            }
            else if (mutation.type === 'attributes') {
                //console.log('The ' + mutation.attributeName + ' attribute was modified.');
            }
            //console.error(mutationsList);
        }
    }

    observer = new MutationObserver(this.callback);

    addObserverIfDesiredNodeAvailable = () => {
        if(!this.targetNode) {
            //The node we need does not exist yet.
            //Wait 500ms and try again
            window.setTimeout(this.addObserverIfDesiredNodeAvailable,500);
            //console.log("no-registered log");
            return;
        }
        // Start observing the target node for configured mutations
        this.observer.observe(this.targetNode,this.config);
        //console.log("registered");
    }

    componentDidMount(){
        
        this.addObserverIfDesiredNodeAvailable();
        //this.handleVisibleWelcomeMessage(false);
        this.targetNode = document.querySelector('#log');
    }

    render(){
        return(
            <div className='dialog'>
                <div className='logo-background'>
                    <img src={logo}/>
                </div>
                <div className='dialog-header'>
                    {/*<div className='menu-icon'>
                        <i className="fas fa-bars"></i>
                    </div>
                    <Notification
                        badge={1}
                        languages={this.props.languages}
                    />*/}
                    <div className='ready-to-accept-calls' onClick={()=>{/*console.error(this.props.callStatus)*/}}>
                        <span style={{visibility:!this.props.callStatus?'visible':'hidden'}}><i className="fas fa-circle"></i> Ready to accept incomming calls</span>
                    </div>
                    <Button 
                        id='button-hangup2' 
                        className='end-call-button' 
                        onClick={()=>{/*console.log(this.props.id)*/}}
                        //callBack={this.handleEndCallButton}
                        //disabled={!this.state.calling}
                    >
                        Finish call
                    </Button>
                    <DropDownMenu
                        translationParameters={ this.props.translationParameters }
                        upDateTranslationParameters={ this.props.upDateTranslationParameters }
                        buttonText='Detect Language'
                        startAutoDetection = { this.props.startAutoDetection }
                        stopAutoDetection={ this.props.stopAutoDetection }
                        callStatus={this.props.callStatus}
                        upDateTranslationParameters={ this.props.upDateTranslationParameters }
                        setLocalLanguage={this.props.setLocalLanguage}
                        setRemoteLanguage={this.props.setRemoteLanguage}
                        setGender = {this.props.setGender}
                    />
                </div>

                <div className='start-call-message' ref={this.exampleRef} style={{display:((this.state.messages>0)?'none':'flex')}}>
                    <span>
                        <i className="fas fa-globe"></i>
                    </span>
                    <h3>Connect a call to start the awesomeness {this.state.messages>0+''}</h3>
                </div>

                <div className='detect-language'>
                    <Alert
                        primaryText='Lets configure your language detection'
                        style={{zIndex:'3'}}
                        buttonText='Detect Language'
                        startAutoDetection = { this.props.startAutoDetection }
                        stopAutoDetection={ this.props.stopAutoDetection }
                        callStatus={this.props.callStatus}
                        upDateTranslationParameters={ this.props.upDateTranslationParameters }
                        setLocalLanguage={this.props.setLocalLanguage}
                        setRemoteLanguage={this.props.setRemoteLanguage}
                    />
                </div>

                <div className='dialog-container' id="log">
                    
                    
                    {/*

                    
                    <div className='message-container message-container-in'>
                        <div className='message-overlay'>
                            <div className='message message-in'>
                                <p>hola</p>
                            </div>
                            <p className='translation-out'>English: hi, how are you?</p>
                        </div>
                    </div>
                    
                    <div className='message-container message-container-out'>    
                        <div className='message-overlay'>
                            <div className='message message-out'>
                                <p>hola como estas?</p>
                            </div>
                            <p className='translation-out'>English: hi, how are you?</p>
                        </div>
                    </div>*/}
                    {/*

                    <div className='message-container'><span className='speaker'>YOU:</span><span className='message'>'+text+'</span></div>
                    
                    <div className='message-container'><span className='speaker'>CALLER:</span><span className='message'>text</span><p className='translation'>'+text2+'</p></div>
                    */}
                    {/*
                    <div className='message-container'>
                        <span className='speaker'>
                            YOU:
                        </span>
                        <span className='message'>
                            hola
                        </span>
                        <p className='translation'>
                            hi, how are you?
                        </p>
                    </div>
                    <div className='message-container'>
                        <span className='speaker'>
                            CALLER:.
                        </span>
                        <span className='message'>
                            Hola como estas?
                        </span>
                        <p className='translation'>
                            hi, how are you?
                        </p>
                    </div>
                    */}
                    {/*<SpinnerCall/>*/}

                </div>
            </div>
        )
    }
}
import React,{useState, useEffect, useRef} from 'react';
import {$} from 'jquery'

import LanguageSelect from '../languageSelect';
import SwitchGender from '../switchGender';
import Switch from '../switch';
import DetectLanguage from '../detectLanguage';

import './styles.scss';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, visible, callback) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            if(visible){
                //alert("You clicked outside of me!");
                callback();
            }
        }
    }
  
    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

const DropDownMenu = (props) => {

    const [state, setState] = useState({visible:false});

    const setVisible = () => {
        //console.error('hola')
        setState({visible:!state.visible});
    }

    const closeDropDown = () => {
        setState({visible:false});
    }

    const cleanLog = () => {
        var logDiv = document.querySelector('#log');
        logDiv.innerHTML = '';
    }

    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, state.visible, closeDropDown);

    const visibilityStyle = {visibility:(state.visible?'visible':'hidden')}

    return (
        <div>
        <div className='background' style={{...visibilityStyle, opacity:(state.visible?'.5':'0')}}></div>
        <div className='dropdown' ref={wrapperRef}>
            <div className="drop-btn" onClick={()=>{setVisible()}}>
                <i className="fas fa-bars"></i>
            </div>
            <ul className='dropdown-content' style={{...visibilityStyle}}>
                <li>
                    <h3 style={{width:'100%', textAlign:'center'}}>Do you need a voice of a</h3>
                    <SwitchGender
                        id={'gender'}
                        default={props.translationParameters!==null?props.translationParameters.ttsGender:'male'}
                        setGender = {(gender) => {props.setGender(gender)}}
                    />
                </li>
                <li>
                    <LanguageSelect
                        title='Local Language'
                        id='language_selection_local2'
                        default={props.translationParameters!==null?props.translationParameters.localLanguage:null}
                        upDateTranslationParameters={props.upDateTranslationParameters}
                    />
                </li>
                <li>
                    <LanguageSelect
                        title='Remote Language'
                        id='language_selection_remote2'
                        default={props.translationParameters!==null?props.translationParameters.remoteLanguage:null}
                        upDateTranslationParameters={props.upDateTranslationParameters}
                    />
                </li>
                <li>
                    <div className='transcript-switch'>
                        <span><i className="fas fa-headset"></i> Transcript mode</span>
                        <Switch
                            name=''
                            id='transcript'
                            style={{display:(state.visible?'':'none')}}
                            checked={false}
                        />
                    </div>
                </li>
                <li>
                    <DetectLanguage
                        text={ props.buttonText }
                        startAutoDetection = { props.startAutoDetection }
                        stopAutoDetection={ props.stopAutoDetection }
                        callStatus={ props.callStatus }
                        upDateTranslationParameters={ props.upDateTranslationParameters }
                        setLocalLanguage={props.setLocalLanguage}
                        setRemoteLanguage={props.setRemoteLanguage}
                        url={'detect-language'}
                    />
                </li>
                <li>
                    <div className='clean-messages' onClick={()=>{cleanLog()}}>
                        <span><i className="fas fa-broom"></i> Clean messages</span>
                    </div>
                </li>
                <li>
                    <a href='/' className='restart'>Logout</a>
                </li>
            </ul>
        </div>
        </div>
    )
}
export default DropDownMenu;
import React,{Component} from 'react';

import './styles.scss';
import Button from '../button';
import ProgressBar from '../progressBar';
import LanguageSelect from '../languageSelect';

var timer;

export default class DetectLanguage extends Component{

    //whichLanguage my or remote
    state = { 
        whichLanguage:'my', 
        percentage:0, 
        recording:false, 
        record:false,
        languages:[],
        newLocalLanguage:'',
        newRemoteLanguage:'',
        active:false
    }

    
    recording = () => {
        //console.error('hola')
        let count = 0;
        this.setState({ recording:true, record:true });
        this.props.startAutoDetection(this.state.whichLanguage);
        timer = setInterval(()=>{ 
            count++;
            if(count===80){
                this.setState({ recording:false });
                this.props.stopAutoDetection(this.detectedLanguage);
                clearInterval(timer); 
            }
            if(count<80){
                this.setState({ percentage:count });
            }
        }, 
        100);
    }

    restart = () => {
        this.setState({ 
            whichLanguage:'my', 
            percentage:0, 
            recording:false, 
            record:false,
            newLocalLanguage:'',
            newRemoteLanguage:'',
            active:false,
        });
        this.props.stopAutoDetection(()=>{});
        clearInterval(timer);
    }

    detectedLanguage = (value) => {
        console.error('detect language',value);
        this.setState({languages:value});
    }

    handleOk = () => {
        console.error(this.state);
        if(this.state.whichLanguage==='my'){
            this.props.setLocalLanguage(this.state.newLocalLanguage);
        }
        else{
            this.props.setRemoteLanguage(this.state.newRemoteLanguage);
        }
        this.props.upDateTranslationParameters();
        this.restart();
    }

    setActive = (active) => {
        this.setState({active});
    }

    render(){
        const languages=['aa,Afar',
        'ab,Abkhazian',
        'af,Afrikaans',
        'ak,Akan',
        'als,Alemannic',
        'am,Amharic',
        'an,Aragonese',
        'ang,Angal',
        'ang,Anglo-Saxon / Old English',
        'ar,Arabic',
        'arc,Aramaic',
        'as,Assamese',
        'ast,Asturian',
        'av,Avar',
        'awa,Awadhi',
        'ay,Aymara',
        'az,Azerbaijani',
        'ba,Bashkir',
        'bar,Bavarian',
        'bat-smg,Samogitian',
        'bcl,Bikol',
        'be,Belarusian',
        'be-x-old,Belarusian (Taraškievica)',
        'bg,Bulgarian',
        'bh,Bihari',
        'bi,Bislama',
        'bm,Bambara',
        'bn,Bengali',
        'bo,Tibetan',
        'bpy,Bishnupriya Manipuri',
        'br,Breton',
        'bs,Bosnian',
        'bug,Buginese',
        'bxr,Buriat (Russia)',
        'ca,Catalan',
        'cdo,Min Dong Chinese',
        'ce,Chechen',
        'ceb,Cebuano',
        'ch,Chamorro',
        'cho,Choctaw',
        'chr,Cherokee',
        'chy,Cheyenne',
        'co,Corsican',
        'cr,Cree',
        'cs,Czech',
        'csb,Kashubian',
        'cu,Old Church Slavonic / Old Bulgarian',
        'cv,Chuvash',
        'cy,Welsh',
        'da,Danish',
        'de,German',
        'diq,Dimli',
        'dsb,Lower Sorbian',
        'dv,Divehi',
        'dz,Dzongkha',
        'ee,Ewe',
        'el,Greek',
        'en,English',
        'eo,Esperanto',
        'es,Spanish',
        'et,Estonian',
        'eu,Basque',
        'ext,Extremaduran',
        'fa,Persian',
        'ff,Peul',
        'fi,Finnish',
        'fiu-vro,Võro',
        'fj,Fijian',
        'fo,Faroese',
        'fr,French',
        'frp,Arpitan / Franco-Provençal',
        'fur,Friulian',
        'fy,West Frisian',
        'ga,Irish',
        'gan,Gan Chinese',
        'gbm,Garhwali',
        'gd,Scottish Gaelic',
        'gil,Gilbertese',
        'gl,Galician',
        'gn,Guarani',
        'got,Gothic',
        'gu,Gujarati',
        'gv,Manx',
        'ha,Hausa',
        'hak,Hakka Chinese',
        'haw,Hawaiian',
        'he,Hebrew',
        'hi,Hindi',
        'ho,Hiri Motu',
        'hr,Croatian',
        'ht,Haitian',
        'hu,Hungarian',
        'hy,Armenian',
        'hz,Herero',
        'ia,Interlingua',
        'id,Indonesian',
        'ie,Interlingue',
        'ig,Igbo',
        'ii,Sichuan Yi',
        'ik,Inupiak',
        'ilo,Ilokano',
        'inh,Ingush',
        'io,Ido',
        'is,Icelandic',
        'it,Italian',
        'iu,Inuktitut',
        'ja,Japanese',
        'jbo,Lojban',
        'jv,Javanese',
        'ka,Georgian',
        'kg,Kongo',
        'ki,Kikuyu',
        'kj,Kuanyama',
        'kk,Kazakh',
        'kl,Greenlandic',
        'km,Cambodian',
        'kn,Kannada',
        'khw,Khowar',
        'ko,Korean',
        'kr,Kanuri',
        'ks,Kashmiri',
        'ksh,Ripuarian',
        'ku,Kurdish',
        'kv,Komi',
        'kw,Cornish',
        'ky,Kirghiz',
        'la,Latin',
        'lad,Ladino / Judeo-Spanish',
        'lan,Lango',
        'lb,Luxembourgish',
        'lg,Ganda',
        'li,Limburgian',
        'lij,Ligurian',
        'lmo,Lombard',
        'ln,Lingala',
        'lo,Laotian',
        'lzz,Laz',
        'lt,Lithuanian',
        'lv,Latvian',
        'map-bms,Banyumasan',
        'mg,Malagasy',
        'man,Mandarin',
        'mh,Marshallese',
        'mi,Maori',
        'min,Minangkabau',
        'mk,Macedonian',
        'ml,Malayalam',
        'mn,Mongolian',
        'mo,Moldovan',
        'mr,Marathi',
        'ms,Malay',
        'mt,Maltese',
        'mus,Creek / Muskogee',
        'mwl,Mirandese',
        'my,Burmese',
        'na,Nauruan',
        'nah,Nahuatl',
        'nap,Neapolitan',
        'nd,North Ndebele',
        'nds,Low German / Low Saxon',
        'nds-nl,Dutch Low Saxon',
        'ne,Nepali',
        'new,Newar',
        'ng,Ndonga',
        'nl,Dutch',
        'nn,Norwegian Nynorsk',
        'no,Norwegian',
        'nr,South Ndebele',
        'nso,Northern Sotho',
        'nrm,Norman',
        'nv,Navajo',
        'ny,Chichewa',
        'oc,Occitan',
        'oj,Ojibwa',
        'om,Oromo',
        'or,Oriya',
        'os,Ossetian / Ossetic',
        'pa,Panjabi / Punjabi',
        'pag,Pangasinan',
        'pam,Kapampangan',
        'pap,Papiamentu',
        'pdc,Pennsylvania German',
        'pi,Pali',
        'pih,Norfolk',
        'pl,Polish',
        'pms,Piedmontese',
        'ps,Pashto',
        'pt,Portuguese',
        'qu,Quechua',
        'rm,Raeto Romance',
        'rmy,Romani',
        'rn,Kirundi',
        'ro,Romanian',
        'roa-rup,Aromanian',
        'ru,Russian',
        'rw,Rwandi',
        'sa,Sanskrit',
        'sc,Sardinian',
        'scn,Sicilian',
        'sco,Scots',
        'sd,Sindhi',
        'se,Northern Sami',
        'sg,Sango',
        'sh,Serbo-Croatian',
        'si,Sinhalese',
        'simple,Simple English',
        'sk,Slovak',
        'sl,Slovenian',
        'sm,Samoan',
        'sn,Shona',
        'so,Somalia',
        'sq,Albanian',
        'sr,Serbian',
        'ss,Swati',
        'st,Southern Sotho',
        'su,Sundanese',
        'sv,Swedish',
        'sw,Swahili',
        'ta,Tamil',
        'te,Telugu',
        'tet,Tetum',
        'tg,Tajik',
        'th,Thai',
        'ti,Tigrinya',
        'tk,Turkmen',
        'tl,Tagalog',
        'tlh,Klingon',
        'tn,Tswana',
        'to,Tonga',
        'tpi,Tok Pisin',
        'tr,Turkish',
        'ts,Tsonga',
        'tt,Tatar',
        'tum,Tumbuka',
        'tw,Twi',
        'ty,Tahitian',
        'udm,Udmurt',
        'ug,Uyghur',
        'uk,Ukrainian',
        'ur,Urdu',
        'uz,Uzbek',
        've,Venda',
        'vi,Vietnamese',
        'vec,Venetian',
        'vls,West Flemish',
        'vo,Volapük',
        'wa,Walloon',
        'war,Waray / Samar-Leyte Visayan',
        'wo,Wolof',
        'xal,Kalmyk',
        'xh,Xhosa',
        'xmf,Megrelian',
        'yi,Yiddish',
        'yo,Yoruba',
        'za,Zhuang',
        'zh,Chinese',
        'zh-classical,Classical Chinese',
        'zh-min-nan,Minnan',
        'zh-yue,Cantonese',
        'zu,Zulu'];

        const findLanguage = (code) => {
            for(let i in languages){
                let aux = languages[i].split(',');
                if(aux[0]===code)
                    return aux[1];
            }
        }

        return (
            <div className='detect-language-container'> 
                {
                    this.props.callStatus?
                        <a href={'#'+this.props.url} className='btn-open-modal' onClick={()=>{this.setActive(true)}}>{this.props.text}</a>
                    :
                        <span className='btn-open-modal disable'>{this.props.text}</span>
                }
                <div id="detect-language" className={'detect-language-modal'+(this.state.active?' active':'')}>
                    <div className="detect-language-modal-content">
                        <a href="#" className='close-btn' onClick={()=>{/*if(!this.state.recording)*/this.restart()}}>
                            <i className="fas fa-times-circle"></i>
                        </a>
                        <h3 style={{ display:(this.state.record?'none':'') }}>Which language do you want to detect?</h3>
                        <div className='switch' style={{ display:(this.state.record?'none':'') }}>
                            <span className={this.state.whichLanguage==='my'?'active':''} onClick={()=>{ this.setState({ whichLanguage:'my'}) }}>My language</span>
                            <span className={this.state.whichLanguage==='remote'?'active':''} onClick={()=>{ this.setState({ whichLanguage:'remote'}) }}>Remote language</span>
                        </div>
                        <p style={{display:(!this.state.record===this.state.recording)?'none':''}}>We need to record a few seconds to do this</p>
                        <div className='timer-container'  style={{ display:(this.state.recording?'':'none') }}>
                            <ProgressBar
                                percentage={ (this.state.percentage/80)*100 }
                            />
                            <div className='timer-counts'>
                                <span>{ '00:0'+(8-parseInt(this.state.percentage/10)) }</span>
                                <span>{ '00:0'+parseInt(this.state.percentage/10) }</span>
                            </div>
                        </div>
                        <div style={{ display:(this.state.record?'none':'') }}>
                            <Button onClick={()=>{this.recording()}} style={{ marginTop:'1em', backgroundColor:'#d88d42', color:'#fff' }}>
                                Start recording <i className="fas fa-microphone-alt"></i>
                            </Button>
                        </div>
                        <div className='language-select' style={{display:(!this.state.record===this.state.recording)?'':'none'}}>
                            {
                                this.state.languages.map((data, index)=>{
                                    return (
                                        <p key={index}>{findLanguage(data.language)+' '+data.percentage+' %'}</p>
                                    )
                                })
                            }
                            {
                                this.state.languages.length===0?<p>No language could be recognized</p>:''
                            }
                            {
                                this.state.whichLanguage==='my'?
                                    <h3>You wanna change the local language?</h3>
                                :
                                    <h3>You wanna change the remote language?</h3>
                            }
                            
                                <LanguageSelect
                                    callback={(value)=>{this.setState({newLocalLanguage:value});}}
                                    style={{display:this.state.whichLanguage==='my'?'':'none'}}
                                />
                                
                                <LanguageSelect
                                    callback={(value)=>{this.setState({newRemoteLanguage:value}); }}
                                    style={{display:this.state.whichLanguage==='my'?'none':''}}
                                />
                            
                            <div className='confirm-container'>
                                <a href='#' onClick={()=>{this.restart()}}>Cancel</a>
                                <a href='#'><Button onClick={()=>{this.handleOk()}} style={{ marginTop:'1em', backgroundColor:'#d88d42', color:'#fff' }}>
                                    Ok
                                </Button></a>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        )
    }
}
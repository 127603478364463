import React from 'react';
import arrow from '../../img/svg/dot-arrow.svg';
import logo from '../../img/LogoWWTC.svg';

import './styles.scss';

const LandingMessage = (props) => {
    return (
        <div className='welcome-message'>
            <div className='logo-background'>
                <img src={logo}/>
            </div>
            <div>
                <h2>Let's start!</h2>
                <p>Config your client an start the awesomeness!</p>
            </div>
            <div>
                <img className='arrow' src={arrow}/>
            </div>
        </div>
    )
}
export default LandingMessage;
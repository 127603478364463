import React,{useState} from 'react';

import './styles.scss';
import Flag from '../Flag';
import LanguageSelect from '../languageSelect';

const Notification = (props) => {
    
    const [state, setState] = useState({whichLanguage:'my'})

    var timer;

    //const start = ()=> { timer = setTimeout(function(){ /*setState({visible:false});*/ props.setVisible(); }, 5000); };

    return (
        <div>
            {/*props.visible?start():''*/}
            <div className={'notification-container '+(props.visible?'visible':'hidden')} style={props.style}>
                <div className={'type '+(props.type==='recognized'?'success':props.type==='processing'?'success':'error')}>
                    {
                        props.type==='recognized'?
                            <i className="fas fa-check"></i>
                        :(
                            props.type==='processing'?
                                <i className="fas fa-circle-notch spinner"></i>
                            :
                                <i className="fas fa-times"></i>
                        )
                    }
                </div>
                <div className={'content'+(props.type==='recognized'?' recognized':'')}>
                    {props.message}
                    <div style={{display:(props.type==='recognized'?'':'none')}}>
                        <Flag
                            country={props.country}
                        />
                    </div>
                    <div className='language-controls' style={{display:(props.type==='recognized'?'':'none')}}>
                        <div className='switch' style={{  }}>
                            <span className={state.whichLanguage==='my'?'active':''} onClick={()=>{ setState({ whichLanguage:'my'}) }}>My language</span>
                            <span className={state.whichLanguage==='remote'?'active':''} onClick={()=>{ setState({ whichLanguage:'remote'}) }}>Remote language</span>
                        </div>
                        {
                            state.whichLanguage==='my'?
                                <h3>You wanna change the local language?</h3>
                            :
                                <h3>You wanna change the remote language?</h3>
                        }
                        <LanguageSelect
                            callback={(value)=>{/*setState({newLocalLanguage:value});*/}}
                            style={{display:(state.whichLanguage==='my'?'':'none')}}
                            id='language_selection_local3'
                            default={props.translationParameters!==null?props.translationParameters.localLanguage:null}
                            upDateTranslationParameters={props.upDateTranslationParameters}
                        />
                                
                        <LanguageSelect
                            callback={(value)=>{/*setState({newRemoteLanguage:value}); */}}
                            style={{display:(state.whichLanguage==='my'?'none':'')}}
                            id='language_selection_remote3'
                            default={props.translationParameters!==null?props.translationParameters.remoteLanguage:null}
                            upDateTranslationParameters={props.upDateTranslationParameters}
                        />
                    </div>
                </div>
                <div className='close-btn' onClick={()=>{props.setVisible(); /*clearInterval(timer);*/}} style={{visibility:((props.type!=='processing')?'visible':'hidden')}}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
        </div>
    )
}
export default Notification;
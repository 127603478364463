import React,{useState} from 'react';

import './styles.scss';

const LogDetail = (props) => {
    const [state, setState] = useState({visible:false});

    const handleVisible = () =>{
        setState({visible:!state.visible});
    }

    return (
        <div className='call-log-container'>
            <span className='gear' onClick={()=>{handleVisible()}}><i className="fas fa-cog"></i></span>
            <div className='log-detail' style={{display:state.visible?'flex':'none'}}>
                <span className='title'><h3>Log detail</h3></span>
                <div className='log-container'>
                    <table>
                        <thead>
                            <tr>
                                <th>Phone number</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.callLog.map((data, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>{data.phone}</td>
                                            <td>{data.date}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default LogDetail;
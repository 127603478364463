import React,{Component} from 'react';

import './styles.scss';

export default class extends Component{
    
    constructor(props){
        super(props);
    }

    targetNode = null;

    // Options for the observer (which mutations to observe)
    config = { attributes: true };

    // Callback function to execute when mutations are observed
    callback = (mutationsList, observer) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                //console.log('A child node has been added or removed.');
            }
            else if (mutation.type === 'attributes') {
                //console.log('The ' + mutation.attributeName + ' attribute was modified.');
                //console.log(mutation)
                if(this.props.callBack && {}.toString.call(this.props.callBack) === '[object Function]')
                    this.props.callBack();
            }
        }
    }

    observer = new MutationObserver(this.callback);

    addObserverIfDesiredNodeAvailable = () => {
        if(!this.targetNode) {
            //The node we need does not exist yet.
            //Wait 500ms and try again
            window.setTimeout(this.addObserverIfDesiredNodeAvailable,500);
            //console.log("no-registered "+this.props.id);
            return;
        }
        // Start observing the target node for configured mutations
        this.observer.observe(this.targetNode,this.config);
        //console.log("registered");
    }
    
    componentDidMount(){
        this.addObserverIfDesiredNodeAvailable();
        this.targetNode = document.querySelector('#'+this.props.id);
    }

    render(){
        const { ...other } = this.props;

        return (
            <div onClick={()=>{this.props.onClick()}}>
                <button
                    style={this.props.styles} 
                    className={'btn btn-'+this.props.type} 
                    id={this.props.id} {...other}
                    disabled={this.props.disabled===undefined?false:this.props.disabled}
                />
            </div>
        )
    }
    
}
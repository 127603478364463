import $ from 'jquery';
import CallProviderTwilio from './CallProviders/CallProviderTwilio';
import CallProviderPlivo from './CallProviders/CallProviderPlivo';
import TYWI from './TYWI';
import TTS from './TTS.js';
import DAROWS from '../lib/darows.js';

import { detectlanguage } from './languageautodetection.js';

var DEBUG = true; // For showing console logs for this component.

// Select the layout between transcript and chat
var LAYOUT = 'chat';

var GENDER = {
    MALE: 'male',
    FEMALE: 'female'
};

var CALL_TYPE = {
    NOT_AVAILABLE: 0,
    INCOMING: 1,
    OUTGOING: 2
};

// DAROWS configuration for processing the Local microphone audio
var speechCaptureCfgLocal = {
    audioResultType: DAROWS.AUDIO_RESULT_TYPE.WAV_BLOB,
    sampleRate: 16000,
    speechDetectionThreshold: 20,
    speechDetectionMinimum: 10,
    speechDetectionMaximum: 6000,
    speechDetectionAllowedDelay: 200,
    analysisChunkLength: 100,
    compressPauses: false,
    detectOnly: false,
    debugAlerts: false,
    debugConsole: false
};

// DAROWS configuration for processing the Remote call audio
var speechCaptureCfgRemote = {
    audioResultType: DAROWS.AUDIO_RESULT_TYPE.WAV_BLOB,
    sampleRate: 16000,
    speechDetectionThreshold: 20,
    speechDetectionMinimum: 10,
    speechDetectionMaximum: 6000,
    speechDetectionAllowedDelay: 200,
    analysisChunkLength: 100,
    compressPauses: false,
    detectOnly: false,
    debugAlerts: false,
    debugConsole: false
};

var processAudioToCallInterval = 100; // milliseconds

var darowsLocal,
    darowsRemote,
    callProvider,
    callProviderId,
    localConfiguration,
    outputVolumeBar,
    inputVolumeBar,
    volumeIndicators,
    bilingual, //maicol forzar bilingue
    translateLocalSwitch,
    translateRemoteSwitch,
    translateLocalSpeech = true, //maicol 
    translateRemoteSpeech = true, //maicol
    connection,
    localStream,
    remoteStream,
    audioContext,
    localMediaStreamForPlayback,
    audioPlaybackSource,
    localPlaybackPrepared = false,

    // Audio to call queue
    audioToCallQueue = [],
    audioToCallIsPlaying = false,
    callInProgress = false,
    callType = CALL_TYPE.NOT_AVAILABLE,

    // For test/debugging purposes
    audioLogEnabled,

    // Translation and TTS related configuration
    translationCfg = {
        localLanguage: 'en-US',
        remoteLanguage: 'en-US',
        ttsVoice: 'Mark',
        ttsGender: GENDER.MALE
    };

    var bilingualRemote=true;
    var remoteText={originalMessage:'', translatedMessage:''};

    var callStatus = {
        isCalling: false,
        callback:null,
        callbackError:null,
    }

    //Auto detection maicol
    var detectionCount = [];
    var languagesDetected = [];
    var autoDetection = '';

    const languages=['aa,Afar',
        'ab,Abkhazian',
        'af,Afrikaans',
        'ak,Akan',
        'als,Alemannic',
        'am,Amharic',
        'an,Aragonese',
        'ang,Angal',
        'ang,Anglo-Saxon / Old English',
        'ar,Arabic',
        'arc,Aramaic',
        'as,Assamese',
        'ast,Asturian',
        'av,Avar',
        'awa,Awadhi',
        'ay,Aymara',
        'az,Azerbaijani',
        'ba,Bashkir',
        'bar,Bavarian',
        'bat-smg,Samogitian',
        'bcl,Bikol',
        'be,Belarusian',
        'be-x-old,Belarusian (Taraškievica)',
        'bg,Bulgarian',
        'bh,Bihari',
        'bi,Bislama',
        'bm,Bambara',
        'bn,Bengali',
        'bo,Tibetan',
        'bpy,Bishnupriya Manipuri',
        'br,Breton',
        'bs,Bosnian',
        'bug,Buginese',
        'bxr,Buriat (Russia)',
        'ca,Catalan',
        'cdo,Min Dong Chinese',
        'ce,Chechen',
        'ceb,Cebuano',
        'ch,Chamorro',
        'cho,Choctaw',
        'chr,Cherokee',
        'chy,Cheyenne',
        'co,Corsican',
        'cr,Cree',
        'cs,Czech',
        'csb,Kashubian',
        'cu,Old Church Slavonic / Old Bulgarian',
        'cv,Chuvash',
        'cy,Welsh',
        'da,Danish',
        'de,German',
        'diq,Dimli',
        'dsb,Lower Sorbian',
        'dv,Divehi',
        'dz,Dzongkha',
        'ee,Ewe',
        'el,Greek',
        'en,English',
        'eo,Esperanto',
        'es,Spanish',
        'et,Estonian',
        'eu,Basque',
        'ext,Extremaduran',
        'fa,Persian',
        'ff,Peul',
        'fi,Finnish',
        'fiu-vro,Võro',
        'fj,Fijian',
        'fo,Faroese',
        'fr,French',
        'frp,Arpitan / Franco-Provençal',
        'fur,Friulian',
        'fy,West Frisian',
        'ga,Irish',
        'gan,Gan Chinese',
        'gbm,Garhwali',
        'gd,Scottish Gaelic',
        'gil,Gilbertese',
        'gl,Galician',
        'gn,Guarani',
        'got,Gothic',
        'gu,Gujarati',
        'gv,Manx',
        'ha,Hausa',
        'hak,Hakka Chinese',
        'haw,Hawaiian',
        'he,Hebrew',
        'hi,Hindi',
        'ho,Hiri Motu',
        'hr,Croatian',
        'ht,Haitian',
        'hu,Hungarian',
        'hy,Armenian',
        'hz,Herero',
        'ia,Interlingua',
        'id,Indonesian',
        'ie,Interlingue',
        'ig,Igbo',
        'ii,Sichuan Yi',
        'ik,Inupiak',
        'ilo,Ilokano',
        'inh,Ingush',
        'io,Ido',
        'is,Icelandic',
        'it,Italian',
        'iu,Inuktitut',
        'ja,Japanese',
        'jbo,Lojban',
        'jv,Javanese',
        'ka,Georgian',
        'kg,Kongo',
        'ki,Kikuyu',
        'kj,Kuanyama',
        'kk,Kazakh',
        'kl,Greenlandic',
        'km,Cambodian',
        'kn,Kannada',
        'khw,Khowar',
        'ko,Korean',
        'kr,Kanuri',
        'ks,Kashmiri',
        'ksh,Ripuarian',
        'ku,Kurdish',
        'kv,Komi',
        'kw,Cornish',
        'ky,Kirghiz',
        'la,Latin',
        'lad,Ladino / Judeo-Spanish',
        'lan,Lango',
        'lb,Luxembourgish',
        'lg,Ganda',
        'li,Limburgian',
        'lij,Ligurian',
        'lmo,Lombard',
        'ln,Lingala',
        'lo,Laotian',
        'lzz,Laz',
        'lt,Lithuanian',
        'lv,Latvian',
        'map-bms,Banyumasan',
        'mg,Malagasy',
        'man,Mandarin',
        'mh,Marshallese',
        'mi,Maori',
        'min,Minangkabau',
        'mk,Macedonian',
        'ml,Malayalam',
        'mn,Mongolian',
        'mo,Moldovan',
        'mr,Marathi',
        'ms,Malay',
        'mt,Maltese',
        'mus,Creek / Muskogee',
        'mwl,Mirandese',
        'my,Burmese',
        'na,Nauruan',
        'nah,Nahuatl',
        'nap,Neapolitan',
        'nd,North Ndebele',
        'nds,Low German / Low Saxon',
        'nds-nl,Dutch Low Saxon',
        'ne,Nepali',
        'new,Newar',
        'ng,Ndonga',
        'nl,Dutch',
        'nn,Norwegian Nynorsk',
        'no,Norwegian',
        'nr,South Ndebele',
        'nso,Northern Sotho',
        'nrm,Norman',
        'nv,Navajo',
        'ny,Chichewa',
        'oc,Occitan',
        'oj,Ojibwa',
        'om,Oromo',
        'or,Oriya',
        'os,Ossetian / Ossetic',
        'pa,Panjabi / Punjabi',
        'pag,Pangasinan',
        'pam,Kapampangan',
        'pap,Papiamentu',
        'pdc,Pennsylvania German',
        'pi,Pali',
        'pih,Norfolk',
        'pl,Polish',
        'pms,Piedmontese',
        'ps,Pashto',
        'pt,Portuguese',
        'qu,Quechua',
        'rm,Raeto Romance',
        'rmy,Romani',
        'rn,Kirundi',
        'ro,Romanian',
        'roa-rup,Aromanian',
        'ru,Russian',
        'rw,Rwandi',
        'sa,Sanskrit',
        'sc,Sardinian',
        'scn,Sicilian',
        'sco,Scots',
        'sd,Sindhi',
        'se,Northern Sami',
        'sg,Sango',
        'sh,Serbo-Croatian',
        'si,Sinhalese',
        'simple,Simple English',
        'sk,Slovak',
        'sl,Slovenian',
        'sm,Samoan',
        'sn,Shona',
        'so,Somalia',
        'sq,Albanian',
        'sr,Serbian',
        'ss,Swati',
        'st,Southern Sotho',
        'su,Sundanese',
        'sv,Swedish',
        'sw,Swahili',
        'ta,Tamil',
        'te,Telugu',
        'tet,Tetum',
        'tg,Tajik',
        'th,Thai',
        'ti,Tigrinya',
        'tk,Turkmen',
        'tl,Tagalog',
        'tlh,Klingon',
        'tn,Tswana',
        'to,Tonga',
        'tpi,Tok Pisin',
        'tr,Turkish',
        'ts,Tsonga',
        'tt,Tatar',
        'tum,Tumbuka',
        'tw,Twi',
        'ty,Tahitian',
        'udm,Udmurt',
        'ug,Uyghur',
        'uk,Ukrainian',
        'ur,Urdu',
        'uz,Uzbek',
        've,Venda',
        'vi,Vietnamese',
        'vec,Venetian',
        'vls,West Flemish',
        'vo,Volapük',
        'wa,Walloon',
        'war,Waray / Samar-Leyte Visayan',
        'wo,Wolof',
        'xal,Kalmyk',
        'xh,Xhosa',
        'xmf,Megrelian',
        'yi,Yiddish',
        'yo,Yoruba',
        'za,Zhuang',
        'zh,Chinese',
        'zh-classical,Classical Chinese',
        'zh-min-nan,Minnan',
        'zh-yue,Cantonese',
        'zu,Zulu'];

    const voices=[
        'ar-EG,Female,ar-EG-Hoda',
        'ar-SA,Male,ar-SA-Naayf',
        'bg-BG,Male,bg-BG-Ivan',
        'ca-ES,Female,ca-ES-HerenaRUS',
        'cs-CZ,Male,cs-CZ-Jakub',
        'da-DK,Female,da-DK-HelleRUS',
        'de-AT,Male,de-AT-Michael',
        'de-CH,Male,de-CH-Karsten',
        'de-CH,Female,de-DE-HeddaRUS',
        'de-CH,Male,de-DE-Stefan-Apollo',
        'el-GR,Male,el-GR-Stefanos',
        'en-AU,Female,en-AU-Catherine',
        'en-AU,Female,en-AU-HayleyRUS',
        'en-CA,Female,en-CA-Linda',
        'en-CA,Female,en-CA-HeatherRUS',
        'en-GB,Female,en-GB-Susan-Apollo',
        'en-GB,Female,en-GB-HazelRUS',
        'en-GB,Male,en-GB-George-Apollo',
        'en-IE,Male,en-IE-Sean',
        'en-IN,Female,en-IN-Heera-Apollo',
        'en-IN,Female,en-IN-PriyaRUS',
        'en-IN,Male,en-IN-Ravi-Apollo',
        'en-US,Female,en-US-ZiraRUS',
        'en-US,Female,en-US-JessaRUS',
        'en-US,Male,en-US-BenjaminRUS',
        'en-US,Female,en-US-Jessa24kRUS',
        'en-US,Male,en-US-Guy24kRUS',
        'es-ES,Female,es-ES-Laura-Apollo',
        'es-ES,Female,es-ES-HelenaRUS',
        'es-ES,Male,es-ES-Pablo-Apollo',
        'es-MX,Female,es-MX-HildaRUS',
        'es-MX,Male,es-MX-Raul-Apollo',
        'fi-FI,Female,fi-FI-HeidiRUS',
        'fr-CA,Female,fr-CA-Caroline',
        'fr-CA,Female,fr-CA-HarmonieRUS',
        'fr-CH,Male,fr-CH-Guillaume',
        'fr-FR,Female,fr-FR-Julie-Apollo',
        'fr-FR,Female,fr-FR-HortenseRUS',
        'fr-FR,Male,fr-FR-Paul-Apollo',
        'he-IL,Male,he-IL-Asaf',
        'hi-IN,Female,hi-IN-Kalpana-Apollo',
        'hi-IN,Female,hi-IN-Kalpana',
        'hi-IN,Male,hi-IN-Hemant',
        'hr-HR,Male,hr-HR-Matej',
        'hu-HU,Male,hu-HU-Szabolcs',
        'id-ID,Male,id-ID-Andika',
        'it-IT,Male,it-IT-Cosimo-Apollo',
        'it-IT,Female,it-IT-LuciaRUS',
        'ja-JP,Female,ja-JP-Ayumi-Apollo',
        'ja-JP,Male,ja-JP-Ichiro-Apollo',
        'ja-JP,Female,ja-JP-HarukaRUS',
        'ko-KR,Female,ko-KR-HeamiRUS',
        'ms-MY,Male,ms-MY-Rizwan',
        'nb-NO,Female,nb-NO-HuldaRUS',
        'nl-NL,Female,nl-NL-HannaRUS',
        'pl-PL,Female,pl-PL-PaulinaRUS',
        'pt-BR,Female,pt-BR-HeloisaRUS',
        'pt-BR,Male,pt-BR-Daniel-Apollo',
        'pt-PT,Female,pt-PT-HeliaRUS',
        'ro-RO,Male,ro-RO-Andrei',
        'ru-RU,Female,ru-RU-Irina-Apollo',
        'ru-RU,Male,ru-RU-Pavel-Apollo',
        'ru-RU,Female,ru-RU-EkaterinaRUS',
        'sk-SK,Male,sk-SK-Filip',
        'sl-SI,Male,sl-SI-Lado',
        'sv-SE,Female,sv-SE-HedvigRUS',
        'ta-IN,Male,ta-IN-Valluvar',
        'te-IN,Female,te-IN-Chitra',
        'th-TH,Male,th-TH-Pattara',
        'tr-TR,Female,tr-TR-SedaRUS',
        'vi-VN,Male,vi-VN-An',
        'zh-CN,Female,zh-CN-HuihuiRUS',
        'zh-CN,Female,zh-CN-Yaoyao-Apollo',
        'zh-CN,Male,zh-CN-Kangkang-Apollo',
        'zh-HK,Female,zh-HK-Tracy-Apollo',
        'zh-HK,Female,zh-HK-TracyRUS',
        'zh-HK,Male,zh-HK-Danny-Apollo',
        'zh-TW,Female,zh-TW-Yating-Apollo',
        'zh-TW,Female,zh-TW-HanHanRUS',
        'zh-TW,Male,zh-TW-Zhiwei-Apollo',
    ];

    const findLanguage = (code) => {
        let auxCode = code.split('-');
        for(let i in languages){
            let aux = languages[i].split(',');
            if(aux[0]===auxCode[0])
                return aux[1];
        }
    }

    function startAutoDetection(value) {
        //console.error('start AutoDetection'+value);
        autoDetection = value;
    }

    function stopAutoDetection(callback) {
        //console.error('stopAutoDetection',languagesDetected);
        callback(languagesDetected);
        //console.error(detectionCount);
        autoDetection = '';
        detectionCount=[];
        languagesDetected=[];
    }

    function autoDetectionCount(response, ok){
        let total=0;
        if(ok){
            detectionCount.push(response);
            //console.error(detectionCount);
            for(let i in detectionCount){
                let exist = false;
                for(let j in languagesDetected){
                    if(detectionCount[i].sourceLanguage===languagesDetected[j].language){
                        exist = true;
                        break;
                    }
                }
                if(!exist && detectionCount[i].sourceLanguage!==""){
                    languagesDetected.push({language: detectionCount[i].sourceLanguage, count:0});
                }
            }
            for(let i in languagesDetected){
                for(let j in detectionCount){
                    if(languagesDetected[i].language===detectionCount[j].sourceLanguage){
                        languagesDetected[i] = {...languagesDetected[i], count: languagesDetected[i].count+1};
                    }
                }
                total += languagesDetected[i].count;
            }
            //best_result={percentage:0};
            for(let i in languagesDetected){
                languagesDetected[i] = {...languagesDetected[i], percentage: (languagesDetected[i].count/total)*100};
                /*if(languagesDetected[i].percentage>best_result.percentage){
                    best_result=languagesDetected[i];
                }*/
            }
            //console.error(languagesDetected)
        }
        else{
            console.error(response);
        }
    }

    function getLanguagesDetected(){
        return languagesDetected;
    }

    function getTranslationParameters(){
        return translationCfg;
    }

    function setGender(gender){
        //console.error(gender)
        translationCfg.ttsGender = gender;
    }

    function changeLocalLanguage(value){
        translationCfg.localLanguage = value;
    }

    function changeRemoteLanguage(value){
        translationCfg.remoteLanguage = value;
    }

    function setIsCalling(value){
        if(value===true || value===false){
            callStatus.isCalling = value;
        }
        else{
            console.error('the value need to be true or false');
        }
    }

    function setCallBack(handleCall, handleError){
        callStatus.callback = handleCall;
        callStatus.callbackError = handleError;
    }

    function getCallStatus(){
        return callStatus;
    }

    function setLocalLanguage(value){
        translationCfg.localLanguage = value;
    }
    
    function setRemoteLanguage(value){
        translationCfg.remoteLanguage = value;
    }

    function setBilingualRemote(value){
        bilingualRemote = value;
    }

    function getVoice(language, gender){
        let voice=null;
        for(let i in voices){
            let aux = voices[i].split(',');
            if(aux[0]===language && aux[1].toUpperCase()===gender.toUpperCase()){
                return aux[2];
            }
            if(aux[0]===language){
                voice = aux[2];
            }
        }
        if(voice!==null)
            return voice;
        return null;
    }

    function translateRemoteSpeechText(speechWAV, remoteLanguage, localLanguage, success) {
        console.log("translateRemoteSpeechText");
        //console.error(autoDetection);
        if(autoDetection==='remote'){
            //console.error('enviando mensajes al autodetector remote');
            detectlanguage(speechWAV, autoDetectionCount);
        }

        fetch('https://oje8wenrt0.execute-api.us-east-2.amazonaws.com/V1/stst/'+remoteLanguage+'/'+localLanguage,
            {
                method: 'POST', 
                body: speechWAV,
                headers:{
                    'Content-Type': 'audio/wav'
                }
            }
        )
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            console.error(myJson);
            success(myJson);
        })
        .catch((error)=>{
            success(error);
        });
    }

/* jshint expr: true */
$(function() {

    // Switch for turning transcript on/off
    translateLocalSwitch = $('#transcript');

    translateLocalSwitch.change(function() {
        if(LAYOUT==='transcript')
            LAYOUT='chat';
        else
            LAYOUT='transcript';
        //maicol console.log('transcript '+(LAYOUT==='transcript'?'active':'inactive'));
    });

    // Gender select box
    document.getElementById("gender").onchange = function() {
        var e = document.getElementById("gender");
        translationCfg.ttsGender = e.options[e.selectedIndex].value;
        DEBUG && console.log("Gender changed to: " + translationCfg.ttsGender);
        setTTSVoiceForRemoteLanguage();
    };
    translationCfg.ttsGender = $('#gender').find(":selected").val();

    // Local language select box
    document.getElementById("language_selection_local").onchange = function() {
        var e = document.getElementById("language_selection_local");
        translationCfg.localLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Local language changed to: " + translationCfg.localLanguage);
    };
    translationCfg.localLanguage = $('#language_selection_local').find(":selected").val();

    // Local language select box dropdown menu
    document.getElementById("language_selection_local2").onchange = function() {
        var e = document.getElementById("language_selection_local2");
        translationCfg.localLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Local language changed to: " + translationCfg.localLanguage);
    };
    translationCfg.localLanguage = $('#language_selection_local2').find(":selected").val();

    document.getElementById("language_selection_local3").onchange = function() {
        var e = document.getElementById("language_selection_local3");
        translationCfg.localLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Local language changed to: " + translationCfg.localLanguage);
    };
    translationCfg.localLanguage = $('#language_selection_local3').find(":selected").val();

    // Local language select autodetection
    /*document.getElementById("language_selection_local3").onchange = function() {
        var e = document.getElementById("language_selection_local3");
        translationCfg.localLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Local language changed to: " + translationCfg.localLanguage);
    };
    translationCfg.localLanguage = $('#language_selection_local3').find(":selected").val();*/

    // Remote language select box
    document.getElementById("language_selection_remote").onchange = function() {
        var e = document.getElementById("language_selection_remote");
        translationCfg.remoteLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Remote language changed to: " + translationCfg.remoteLanguage);
        setTTSVoiceForRemoteLanguage();
    };
    translationCfg.localLanguage = $('#language_selection_remote').find(":selected").val();

    // Remote language select box dropdown menu
    document.getElementById("language_selection_remote2").onchange = function() {
        var e = document.getElementById("language_selection_remote2");
        translationCfg.remoteLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Remote language changed to: " + translationCfg.remoteLanguage);
        setTTSVoiceForRemoteLanguage();
    };
    translationCfg.localLanguage = $('#language_selection_remote2').find(":selected").val();
    
    document.getElementById("language_selection_remote3").onchange = function() {
        var e = document.getElementById("language_selection_remote3");
        translationCfg.remoteLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Remote language changed to: " + translationCfg.remoteLanguage);
        setTTSVoiceForRemoteLanguage();
    };
    translationCfg.localLanguage = $('#language_selection_remote3').find(":selected").val();

    
    // Remote language select autodetection
    /*document.getElementById("language_selection_remote3").onchange = function() {
        var e = document.getElementById("language_selection_remote3");
        translationCfg.remoteLanguage = e.options[e.selectedIndex].value;
        DEBUG && console.log("Remote language changed to: " + translationCfg.remoteLanguage);
        setTTSVoiceForRemoteLanguage();
    };
    translationCfg.localLanguage = $('#language_selection_remote3').find(":selected").val();*/

    // Init default language settings
    setTTSVoiceForRemoteLanguage();

    /**
     * Initializes the GUI
     */
    function initGUI() {
        outputVolumeBar = $('#output-volume');
        inputVolumeBar = $('#input-volume');
        volumeIndicators = $('#volume-indicators');

        // Ready button
        $('#ready-button').on('click', function() {
            $('#ready-button').attr("disabled", true);
            init();
        });

        // Hangup call
        $('#button-hangup').on('click', function() {
            if (callProvider) {
                callProvider.hangup();
            }
        });
        // Hangup call 2 maicol
        $('#button-hangup2').on('click', function() {
            if (callProvider) {
                callProvider.hangup();
            }
        });

        // Make outgoing call
        $('#button-call').on('click', function() {
            var callTo = document.getElementById('phone-number').value;

            if (!isAValidPhoneNumber(callTo)) {
                //alert("Please enter a valid phone number in international format starting with + character"); maicol numero invalido
            } else {
                DEBUG && console.log('Calling ' + callTo + '...');

                setIsCalling(true); //maicol start call
                callStatus.callback(true);//maicol start call

                if (callProvider) {
                    callProvider.call(callTo);
                    callType = CALL_TYPE.OUTGOING;
                }
            }
        });

        // Make outgoing call 2
        $('#button-call2').on('click', function() {
            var callTo = document.getElementById('phone-number').value;

            if (!isAValidPhoneNumber(callTo)) {
                alert("Please enter a valid phone number in international format starting with + character");
            } else {
                DEBUG && console.log('Calling ' + callTo + '...');

                setIsCalling(true); //maicol start call
                callStatus.callback(true);//maicol start call

                if (callProvider) {
                    callProvider.call(callTo);
                    callType = CALL_TYPE.OUTGOING;
                }
            }
        });

        // Switch for turning bilingual on/off
        bilingual = true;

        $('#Bilingual').change(function() {
            //console.log('Bilingual '+this.checked)
            bilingual = !bilingual;
        });

        // Switch for turning translation of local speech translation on/off
        translateLocalSwitch = $('#translateLocal');

        translateLocalSwitch.change(function() {
            setTranslateLocalSpeech(this.checked);
        });

        // Switch for turning translation of remote speech translation on/off
        translateRemoteSwitch = $('#translateRemote');

        translateRemoteSwitch.change(function() {
            setTranslateRemoteSpeech(this.checked);
        });
    }

    /**
     * Initializes the web app. Should only be called from a user interaction to enable audio on the web page.
     */
    function init() {
        var errorMsg = "You must create a file named 'local-configuration.json' in the project root.";

        // First we load the local-configuration file, so that each developer can use his own configuration.
        $.getJSON("local-configuration.json", function(localCfg) {
            // Create a Web Audio context if one doesn't already exist.
            if (!audioContext) {
                /* jshint -W056 */
                // noinspection JSUnresolvedVariable
                audioContext = new(window.AudioContext || window.webkitAudioContext)();
            }

            speechCaptureCfgLocal.audioContext = audioContext;
            speechCaptureCfgRemote.audioContext = audioContext;

            DEBUG && console.log("Loaded local configuration", localCfg);
            localConfiguration = localCfg;

            // Create a DAROWS instance for processing the audio coming from the remote participant (phone call).
            darowsRemote = new DAROWS();

            // Create a DAROWS instance for processing the audio coming from the
            // local participant (web browser/microphone).
            darowsLocal = new DAROWS();

            // Initialize TYWI integration
            // noinspection JSUnresolvedVariable
            TYWI.init(localConfiguration.TYWI, log);

            // Initialize the selected CallProvider
            // noinspection JSUnresolvedVariable
            initCallProvider(localConfiguration.useCallProvider);

            setTranslateLocalSpeech(translateLocalSwitch.is(":checked"));
            setTranslateRemoteSpeech(translateRemoteSwitch.is(":checked"));

            // If audio log with the captured audio should be shown on screen. (debug)
            // noinspection JSUnresolvedVariable
            if (localConfiguration.audioLog) {
                // Show log for captured audio from the local microphone.
                $('#localAudioLog').fadeIn(500);

                // Show log for captured audio from the phone call.
                $('#audioLog').fadeIn();
                audioLogEnabled = true;
            }
        }).catch(function(ex) {
            console.error(ex);
            alert(errorMsg);
        });
    }

    /**
     * Initializes the chosen Call Provider (see: js/CallProviders/)
     *
     * @param id - The identification of the CallProvider. Currently 'twilio' and 'plivo' are supported.
     */
    var callbacksObj;
    function initCallProvider(id) {

        // Prepare object with callbacks that will be used by the CallProvider to communicate with our app.
        callbacksObj = {
            ready: callProviderReady,
            error: callProviderError,
            connected: callProviderConnected,
            disconnected: callProviderDisconnected,
            incoming: callProviderIncoming,
            ringing: callProviderRinging,
            exception: callProviderException,
            volume: callProviderVolume
        };

        id = id.toLowerCase();

        switch (id) {
            case "twilio":
                callProviderId = 'twilio';
                callProvider = new CallProviderTwilio();
                break;
            case "plivo":
                callProviderId = 'plivo';
                callProvider = new CallProviderPlivo();
                break;
            default:
                callProviderId = null;
                if (id) {
                    //maicol log("CallProvider '" + id + "' is not supported! Use 'twilio' or 'plivo' instead.");
                } else {
                    //maicol log("No callProvider (useCallProvider) set in the local configuration!");
                }
                return;
        }

        // noinspection JSUnresolvedVariable
        DEBUG && console.info("CallProvider used: [" + callProviderId + "]", localConfiguration.callProviders);

        // noinspection JSUnresolvedVariable
        if (localConfiguration && localConfiguration.callProviders && localConfiguration.callProviders.hasOwnProperty(callProviderId)) {
            // noinspection JSUnresolvedVariable
            callProvider.init(localConfiguration.callProviders[callProviderId], callbacksObj, log);
            //console.error('maicol',callbacksObj, log);
        } else {
            console.error("initTwilio ex: No call provider configuration provided for: " + callProviderId);
        }
    }

    /**
     *
     * @param twilioConnection
     * @param twilioLocalStream
     * @param twilioRemoteStream
     */
    function prepareLocalPlayback(twilioConnection, twilioLocalStream, twilioRemoteStream) {
        connection = twilioConnection;

        // todo: Could use twilioConnection.direction (OUTGOING|INCOMING) instead for setting the callType
        if (callType === CALL_TYPE.OUTGOING) {
            setTimeout(function() {
                DEBUG && console.log('prepareLocalPlayback [outgoing-call]', twilioConnection, twilioConnection.getLocalStream(), twilioConnection.getRemoteStream());
                localStream = twilioConnection.getLocalStream();
                remoteStream = twilioConnection.getRemoteStream();
                startRemoteSpeechCapture(twilioConnection.getRemoteStream());
                setLocalPlayback(isTranslateLocalSpeech());
                startLocalSpeechCapture();
            }, 1000);
        } else {
            DEBUG && console.log('prepareLocalPlayback [incoming-call]', twilioConnection, twilioLocalStream, twilioRemoteStream);
            localStream = twilioLocalStream;
            remoteStream = twilioRemoteStream;
            startRemoteSpeechCapture(remoteStream);
            setLocalPlayback(isTranslateLocalSpeech());
            startLocalSpeechCapture();
        }
    }

    /**
     * Sets everything up for playing of audio files back to the call instead of using the microphone.
     *
     * todo: Currently playback of mp3 files are only supported using Twilio as the CallProvider.
     *
     * @param active
     */
    function setLocalPlayback(active) {
        DEBUG && console.log("setLocalPlayback for provider " + callProviderId + ": " + active);

        if (!localPlaybackPrepared) {
            if (active) {
                if (callProviderId === 'twilio') {
                    // Mute the Twilio stream for the local microphone, since we instead want to playback audio files to the call.
                    muteMicrophone(true);

                    // If no MediaStreamDestination exists, create one.
                    if (!localMediaStreamForPlayback) {
                        localMediaStreamForPlayback = audioContext.createMediaStreamDestination();
                    }

                    // For getting the MediaStreamTrack if needed.
                    //var mediaStreamTrack = localMediaStreamForPlayback.stream.getAudioTracks()[0];

                    // Force the Twilio PeerConnection to use our custom stream in addition to the microphone stream.
                    connection.mediaStream.setInputTracksFromStream(localMediaStreamForPlayback.stream);

                    // Ensure that the track for audio playback is enabled (not muted).
                    // The Twilio stream should now have two tracks, one from the microphone (muted) and one from the
                    // localMediaStreamForPlayback which we created, which is not muted.
                    localMediaStreamForPlayback.stream.getAudioTracks()[0].enabled = true;

                    localPlaybackPrepared = true;
                } else {
                    console.warn('Playing files back to the call is only possible with Twilio as the CallProvider.');
                }
            } else {
                muteMicrophone(false);
            }
        }
    }

    /**
     *
     * @param active
     */
    function setTranslateLocalSpeech(active) {
        if (active !== translateLocalSpeech) {
            translateLocalSpeech = active;
            DEBUG && console.log("Translation of local speech from microphone: " + translateLocalSpeech);

            if (connection) {
                setLocalPlayback(translateLocalSpeech);
            }
        }
    }

    /**
     *
     * @param active
     */
    function setTranslateRemoteSpeech(active) {
        if (active !== translateRemoteSpeech) {
            translateRemoteSpeech = active;
            DEBUG && console.log("Translation of remote speech from call: " + translateRemoteSpeech);
        }
    }

    /**
     *
     * @returns {boolean}
     */
    function isTranslateLocalSpeech() {
        return translateLocalSpeech;
    }

    /**
     *
     * @returns {boolean}
     */
    function isTranslateRemoteSpeech() {
        return translateRemoteSpeech;
    }

    /**
     * Resets local playback
     */
    function resetLocalPlayback() {
        DEBUG && console.log("resetLocalPlayback");
        callStatus.callback(false);
        setIsCalling(false); //maicol end call

        //console.error('maicol', callbacksObj);

        connection = null;
        localStream = null;
        remoteStream = null;
        localPlaybackPrepared = false;

        // If the last audio exists, stop it.
        if (audioPlaybackSource) {
            audioPlaybackSource.stop();
            audioPlaybackSource = null;
        }
    }

    /**
     * Mutes the local microphone
     *
     * @param shouldMute
     */
    function muteMicrophone(shouldMute) {
        DEBUG && console.log("muteMicrophone: " + shouldMute);
        if (connection && connection.mediaStream) {
            connection.mediaStream.mute(shouldMute);
        }
    }

    /**
     * Plays the specified Web Audio AudioBuffer to the call.
     * The audio will only be heard by the remote party.
     *
     * @param message An object containing audio (Web Audio buffer), originalMessage (String) and translatedMessage (String).
     */
    function playbackAudioToCall(message) {
        //console.error(message);
        if (localPlaybackPrepared && localMediaStreamForPlayback) {
            audioToCallIsPlaying = true;
            var audioBuffer = message.audio;
            var originalMessage = message.originalMessage;
            var translatedMessage = message.translatedMessage;

            DEBUG && console.log("playbackAudioToCall - START [" + originalMessage + "] -> [" + translatedMessage + "] -> [" + audioBuffer.length + "]");
            audioPlaybackSource = audioContext.createBufferSource();
            audioPlaybackSource.buffer = audioBuffer;
            audioPlaybackSource.connect(localMediaStreamForPlayback);
            audioPlaybackSource.onended = function() {
                audioToCallIsPlaying = false;
                DEBUG && console.log("playbackAudioToCall - END [" + originalMessage + "] -> [" + translatedMessage + "] -> [" + audioBuffer.length + "]");
            };
            var languagename = document.getElementById("language_selection_remote");
            languagename = languagename.options[languagename.selectedIndex].innerText;
            languagename = languagename.split(",");

            if (bilingual) {
                //on then set bilingual
                logLocalSpeech(originalMessage, languagename[0] + ": " + translatedMessage);
            } else {
                //if the toggle is on/off 
                logLocalSpeech(originalMessage);
            }

            audioPlaybackSource.start();
        }
    }

    /**
     * Activity log
     *
     * @param message
     */
    function log(message) {
        if (message) {
            var logDiv = document.getElementById('log');
            //maicol logDiv.innerHTML += '<p>&gt;&nbsp;' + message + '</p>'; WWTC init - Successful! listo para hacer llamadas
            logDiv.scrollTop = logDiv.scrollHeight;
        }
    }

    /**
     * Starts DAROWS audio chunking for the local microphone.
     */
    function startLocalSpeechCapture() {
        darowsLocal.start(speechCaptureCfgRemote, handleSpeechCaptureResultLocal, handleSpeechCaptureErrorLocal, handleSpeechCaptureEventLocal);
        DEBUG && console.log("Local SpeechCapture started.");
    }

    /**
     * Stop capturing audio from the local microphone.
     */
    function stopLocalSpeechCapture() {
        DEBUG && console.log("stopLocalSpeechCapture");
        darowsLocal.stop();
    }

    /**
     * Start capturing and chunking audio from the incoming call (remote) using DAROWS
     *
     * @param stream - The audio stream from the call.
     */
    function startRemoteSpeechCapture(stream) {
        DEBUG && console.log("startRemoteSpeechCapture");
        darowsRemote.start(speechCaptureCfgLocal, handleSpeechCaptureResultRemote, handleSpeechCaptureErrorRemote, handleSpeechCaptureEventRemote, stream);
    }

    /**
     * Stop capturing the audio from the call
     */
    function stopRemoteSpeechCapture() {
        DEBUG && console.log("stopRemoteSpeechCapture");
        darowsRemote.stop();
    }


    /**
     * Called whenever speech has been captured.
     * WAV_BLOB type is used for speech recognition
     *
     * @param audioData - The captured audio.
     * @param type - Should always be DAROWS.AUDIO_RESULT_TYPE.WAV_BLOB in this case.
     */
    function handleSpeechCaptureResultLocal(audioData, type) {
        switch (type) {
            case DAROWS.AUDIO_RESULT_TYPE.WAV_BLOB:
                if (audioLogEnabled) {
                    appendWAVAudioBufferLocal(audioData);
                }

                if (isTranslateLocalSpeech()) {
                    translateLocalSpeechTTS(audioData);
                } else {
                    var fileReader = new FileReader();
                    fileReader.onload = function(event) {
                        // noinspection JSUnresolvedVariable
                        var arrayBuffer = event.target.result;
                        audioContext.decodeAudioData(arrayBuffer).then(function(buffer) {
                            DEBUG && console.log("handleSpeechCaptureResultLocal.decodeAudioData", buffer);

                            // Push the audio to the outgoing audio queue that will be played to the phone call.
                            audioToCallQueue.push({
                                audio: buffer,
                                originalMessage: null,
                                translatedMessage: null
                            });
                        });
                    };
                    fileReader.readAsArrayBuffer(audioData);
                }
                break;
            default:
                console.warn("handleSpeechCaptureResultLocal: Results of type " + type + " is not supported!");
                break;
        }
    }

    /**
     * Show errors raised by the speech capture.
     *
     * @param error
     */
    function handleSpeechCaptureErrorLocal(error) {
        console.error("handleSpeechCaptureErrorLocal", error);
    }

    /**
     * Handles speech capture events from the local microphone. Should probably only be used for debugging purposes and
     * then removed in production.
     *
     * todo: Remove in final version?
     *
     * @param code - The error code.
     */
    function handleSpeechCaptureEventLocal(code) {
        DEBUG && logSpeechCaptureEvent(code, "* handleSpeechCaptureEventLocal: ");
    }

    /**
     *
     * @param code
     * @param prefix
     */
    function logSpeechCaptureEvent(code, prefix) {
        switch (code) {
            case DAROWS.STATUS.CAPTURE_STARTED:
                console.log(prefix + "Capture Started!");
                break;
            case DAROWS.STATUS.CAPTURE_STOPPED:
                console.log(prefix + "Capture Stopped!");
                break;
            case DAROWS.STATUS.SPEECH_STARTED:
                console.log(prefix + "Speech Started!");
                break;
            case DAROWS.STATUS.ENCODING_ERROR:
                console.log(prefix + "Encoding Error!");
                break;
            case DAROWS.STATUS.CAPTURE_ERROR:
                console.log(prefix + "Capture Error!");
                break;
            case DAROWS.STATUS.SPEECH_ERROR:
                console.log(prefix + "Speech Error!");
                break;
            case DAROWS.STATUS.SPEECH_MAX_LENGTH:
                console.log(prefix + "Max Speech length!");
                break;
            case DAROWS.STATUS.SPEECH_MIN_LENGTH:
                console.log(prefix + "Min Speech length!");
                break;
            case DAROWS.STATUS.SPEECH_STOPPED:
                console.log(prefix + "Speech Stopped!");
                break;
            default:
                console.warn(prefix + "Unknown status occurred", code);
                break;
        }
    }


    /**
     * Append the captured microphone audio to the on-screen audio log.
     *
     * @param audioBuffer
     */
    function appendWAVAudioBufferLocal(audioBuffer) {
        //console.error('audio hola', audioBuffer);
        try {
            var reader = new FileReader();
            reader.onload = function(evt) {
                var audio = document.createElement("AUDIO");
                audio.controls = true;
                // noinspection JSUnresolvedVariable
                audio.src = evt.target.result;
                audio.type = "audio/wav";
                document.getElementById("localAudioLog").appendChild(audio);
            };
            reader.readAsDataURL(audioBuffer);
        } catch (ex) {
            console.error("appendWAVAudioBufferLocal ex: " + ex);
        }
    }


    /**
     * Called whenever speech has been captured from the remote call.
     * WAV_BLOB type is used for speech recognition
     *
     * @param audioData - The captured audio from the remote call.
     * @param type - Should always be DAROWS.AUDIO_RESULT_TYPE.WAV_BLOB in this case.
     */
    function handleSpeechCaptureResultRemote(audioData, type) {
        //console.error(audioData, type);
        switch (type) {
            case DAROWS.AUDIO_RESULT_TYPE.WAV_BLOB:
                if (audioLogEnabled) {
                    appendWAVAudioBufferRemote(audioData);
                }

                if (isTranslateRemoteSpeech()) {
                    translateRemoteSpeechText(audioData, function(text, text2) {
                        logRemoteCaller(text, text2);
                    }, function(error) {
                        console.error("handleSpeechCaptureResultRemote", error);
                    });
                }

                break;
            default:
                console.warn("handleSpeechCaptureResultRemote: Results of type " + type + " is not supported!");
                break;
        }
    }

    /**
     * Show remote speech on log screen.
     *
     * @param text
     */
    function logRemoteCaller(text, text2) {
        var logDiv = document.getElementById('log');
        //maicol text = "CALLER: " + text;
        if(LAYOUT==='transcript'){
            var today = new Date();
            var time = '['+today.getHours()+":"+today.getMinutes()+":"+today.getSeconds()+']';
            //text = time+" "+text;
            var speaker = time+' CALLER: ';
            //console.error(text, text2);
            if(bilingualRemote){
                logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message caller\'>'+text+'</span><p class=\'translation\'>'+findLanguage(translationCfg.localLanguage)+' '+text2+'</p></div>';
            }
            else{
                logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message caller\'>'+text+'</span></div>';
            }
        }
        else{
            //console.error(text);
            if(bilingualRemote){
                logDiv.innerHTML += '<div class=\'message-container message-container-in\'><div class=\'message-overlay\'><div class=\'message message-in\'><p>'+text+'</p></div><p class=\'translation-out\'>'+findLanguage(translationCfg.localLanguage)+' '+text2+'</p></div></div>';
            }
            else{
                logDiv.innerHTML += '<div class=\'message-container\'><div class=\'message message-in\'><p>'+text+'</p></div></div>';
            }
        }
        logDiv.scrollTop = logDiv.scrollHeight;
    }

    /**
     * Show local speech on log screen.
     *
     * @param text
     */
    function logLocalSpeech(text, text2) {
        text2 = text2 || 0;
        if (text) {
            var logDiv = document.getElementById('log');

            if(LAYOUT==='transcript'){
                var today = new Date();
                var time = '['+today.getHours()+":"+today.getMinutes()+":"+today.getSeconds()+']';
                //text = time+" "+text;
                var speaker = time + '';
                if (text2 !== 0)
                    logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message\'>'+text+'</span><p class=\'translation\'>'+text2+'</p></div>';
                else
                    logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message\'>'+text+'</span></div>';
            }
            else{
                if (text2 !== 0)
                    logDiv.innerHTML += '<div class=\'message-container message-container-out\'><div class=\'message-overlay\'><div class=\'message message-out\'><p>'+text+'</p></div><p class=\'translation-out\'>'+text2+'</p></div></div>';
                else
                    logDiv.innerHTML += '<div class=\'message-container message-container-out\'><div class=\'\'><div class=\'message message-out\'><p>'+text+'</p></div></div></div>';
            }
            logDiv.scrollTop = logDiv.scrollHeight;
        }
    }

    /**
     * Handle errors raised by the speech capture of the remote call.
     *
     * @param error - The error code.
     */
    function handleSpeechCaptureErrorRemote(error) {
        console.error("handleSpeechCaptureErrorRemote", error);
    }

    /**
     * Handles speech capture events from the remote call. Should probably only be used for debugging purposes and then
     * removed in production.
     *
     * todo: Remove in final version?
     *
     * @param code
     */
    function handleSpeechCaptureEventRemote(code) {
        DEBUG && logSpeechCaptureEvent(code, "* handleSpeechCaptureEventRemote: ");
    }

    /**
     * Append the captured audio from the remote phone call to the on-screen audio log.
     *
     * @param audioBuffer
     */
    function appendWAVAudioBufferRemote(audioBuffer) {
        //console.error('audio hola', audioBuffer);
        try {
            var reader = new FileReader();
            reader.onload = function(evt) {
                var audio = document.createElement("AUDIO");
                audio.controls = true;
                // noinspection JSUnresolvedVariable
                audio.src = evt.target.result;
                audio.type = "audio/wav";
                document.getElementById("audioLog").appendChild(audio);
            };
            reader.readAsDataURL(audioBuffer);
        } catch (ex) {
            console.error("appendWAVAudioBufferRemote ex: " + ex);
        }
    }

    /**
     * Called when the selected CallProvider is ready to accept incoming calls.
     */
    function callProviderReady() {
        //maicol log('CallProvider - Ready to accept incoming calls!');
        //document.getElementById('call-controls').style.display = 'flex';
        $('#button-call').show();
        $('#ready-button').hide();
        $('#button-hangup').hide();
    }

    /**
     * Show errors from the CallProvider.
     *
     * @param err
     */
    function callProviderError(err) {
        console.error('CallProvider - Error: ' + err);
        callStatus.callbackError();
        //maicol erro log('CallProvider - Error: ' + err);
    }

    /**
     * Called when the CallProvider has connected the call.
     *
     * @param remoteStream - The audio stream from the phone call.
     * @param localStream - The audio stream from the local microphone.
     * @param connection - The call connection.
     */
    function callProviderConnected(remoteStream, localStream, connection) {
        //maicol log('CallProvider - Call connected!', remoteStream, localStream, connection);
        $('#button-call').hide();
        //$('#button-hangup').show();
        document.getElementById('button-hangup').style.display = 'flex';
        if (callProvider.supportsVolumeIndicators) {
            volumeIndicators.show();
        }
        TYWI.start();
        callInProgress = true;
        audioToCallQueue = [];
        audioToCallIsPlaying = false;
        localPlaybackPrepared = false;
        processAudioToCallQueue();
        prepareLocalPlayback(connection, localStream, remoteStream);
    }

    /**
     * Called when the CallProvider has disconnected an active call.
     */
    function callProviderDisconnected() {
        //maicol console.error('CallProvider - Call ended!',parameter); llamada finalizada
        TYWI.stop();
        callInProgress = false;
        callType = CALL_TYPE.NOT_AVAILABLE;
        $('#button-call').show();
        $('#button-hangup').hide();
        volumeIndicators.hide();
        stopLocalSpeechCapture();
        stopRemoteSpeechCapture();
        resetLocalPlayback();
    }

    /**
     * Called when there is an incoming call.
     *
     * @param from - Should contain the number of the calling party.
     */
    function callProviderIncoming(from) {
        /*maicol log('CallProvider - Incoming call from: ' + from);*/ //llamada entrante

        callType = CALL_TYPE.INCOMING;

        // Automatically answer the call.
        callProvider.answer();
    }

    /**
     * Called when the CallProvider indicates ring tone. Only called when calling from the web app to a number?
     *
     * @param call
     */
    function callProviderRinging(call) {
        //maicol log('CallProvider - Ringing: ' + call); llamada repicando
    }

    /**
     * Used to indicate volume of input and output. Only some CallProviders support this.
     *
     * @param inputVolume
     * @param outputVolume
     */
    function callProviderVolume(inputVolume, outputVolume) {
        var inputColor = 'red';
        if (inputVolume < 0.50) {
            inputColor = 'green';
        } else if (inputVolume < 0.75) {
            inputColor = 'yellow';
        }

        inputVolumeBar.css("width", Math.floor(inputVolume * 300) + 'px');
        inputVolumeBar.css("background", inputColor);

        var outputColor = 'red';
        if (outputVolume < 0.50) {
            outputColor = 'green';
        } else if (outputVolume < 0.75) {
            outputColor = 'yellow';
        }

        outputVolumeBar.css("width", Math.floor(outputVolume * 300) + 'px');
        outputVolumeBar.css("background", outputColor);
    }

    /**
     * Called when there is an unhandled exception in the CallProvider code.
     *
     * @param ex - The exception message.
     */
    function callProviderException(ex) {
        //maicol log('CallProvider - Exception: ' + ex); llamada exceccion
        $('#button-hangup').hide();
    }


    function toblob(b64Data, contentType, sliceSize) {
        let byteCharacters = atob(b64Data)
        let byteArrays = [];
        
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize)
          
          let byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          
          let byteArray = new Uint8Array(byteNumbers)
          
          byteArrays.push(byteArray)
        }
        
        let blob = new Blob(byteArrays, {type: contentType})
        return blob
    }

    /**
     * Transcribes, translates, requests synthetic speech for the specified local audio and then queues it to play to the
     * incoming call using translationCfg object.
     *
     * @param speechWAV The audio to transcribe, translate and get synthetic speech for.
     */
    function translateLocalSpeechTTS(speechWAV) {

        //console.error("translateLocalSpeechTTS");
        var transcribedMessage = null;
        var translatedMessage = null;

        if(autoDetection==='my'){
            //console.error('enviando mensajes al autodetector local');
            detectlanguage(speechWAV, autoDetectionCount);
        }

        //console.error(translationCfg.localLanguage+'  '+translationCfg.remoteLanguage+'  '+getVoice(translationCfg.remoteLanguage,translationCfg.ttsGender));

        fetch('https://oje8wenrt0.execute-api.us-east-2.amazonaws.com/V1/stst/'+translationCfg.localLanguage+'/'+translationCfg.remoteLanguage+'/'+getVoice(translationCfg.remoteLanguage,translationCfg.ttsGender),
            {
                method: 'POST', 
                body: speechWAV,
                headers:{
                    'Content-Type': 'audio/wav'
                }
            }
        )
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            //console.error(myJson);
           

            var Sound = (function () {
                var df = document.createDocumentFragment();
                return function Sound(src) {
                    var snd = new Audio(src);
                    df.appendChild(snd); // keep in fragment until finished playing
                    snd.addEventListener('ended', function () {df.removeChild(snd);});
                    //snd.play();
                    return snd;
                }
            }());
            
            var snd = new Audio("data:audio/wav;base64," + myJson.audio_base64);

            //let b64toBlob = toblob(snd,'',512);

            //console.error('sonido',snd.buffered);
            if(myJson.translated_text!=='')
            fetch(snd.src)
            .then(function(res) {
                res.blob().then(function(blob) {
                    //console.error(blob,'fetch blob',speechWAV); descargar audio
                    
                    var fileReader = new FileReader();
                    fileReader.onload = function(event) {
                        // noinspection JSUnresolvedVariable
                        var arrayBuffer = event.target.result;
                        audioContext.decodeAudioData(arrayBuffer).then(function(buffer) {
                            // Push the audio to the outgoing audio queue that will be played to the phone call.
                            audioToCallQueue.push({
                                audio: buffer,
                                originalMessage: '',
                                translatedMessage: ''
                            });
                        });
                    };
                    fileReader.readAsArrayBuffer(blob);
                });
                //console.error(res,'fetch');
            });



            var logDiv = document.getElementById('log');

            if(LAYOUT==='transcript'){
                var today = new Date();
                var time = '['+today.getHours()+":"+today.getMinutes()+":"+today.getSeconds()+']';
                //text = time+" "+text;
                var speaker = time + '';
                if(myJson.translated_text!=='')
                    if (bilingual)
                        logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message\'>'+myJson.source_text+'</span><p class=\'translation\'>'+findLanguage(translationCfg.remoteLanguage)+' '+myJson.translated_text+'</p></div>';
                    else
                        logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message\'>'+myJson.translated_text+'</span></div>';
            }
            else{
                if(myJson.translated_text!=='')
                    if(bilingual)
                        logDiv.innerHTML += '<div class=\'message-container message-container-out\'><div class=\'message-overlay\'><div class=\'message message-out\'><p>'+myJson.source_text+'</p></div><p class=\'translation-out\'>'+findLanguage(translationCfg.remoteLanguage)+' '+myJson.translated_text+'</p></div></div>';
                    else
                        logDiv.innerHTML += '<div class=\'message-container message-container-out\'><div class=\'\'><div class=\'message message-out\'><p>'+myJson.source_text+'</p></div></div></div>';
            }
            logDiv.scrollTop = logDiv.scrollHeight;
        })
        .catch(error=>{
            console.error(error);
        });
    }

/*
        TYWI.transcribeSpeech(
            speechWAV,
            translationCfg.localLanguage,
            function(transcribedSpeechText) {
                console.log("translateLocalSpeechTTS.transcribeSpeech", transcribedSpeechText);
                transcribedMessage = transcribedSpeechText;
                TYWI.translateText(
                    transcribedSpeechText,
                    getLocalLanguageShort(),
                    getRemoteLanguageShort(),
                    function(translatedText) {
                        console.log("translateLocalSpeechTTS.translateText", translatedText);
                        translatedMessage = translatedText;
                        TYWI.requestAudioTTS(
                            translatedText,
                            getRemoteLanguageShort(),
                            translationCfg.ttsVoice,
                            function(mp3Blob) {
                                console.error("translateLocalSpeechTTS.requestAudioTTS", mp3Blob);
                                var fileReader = new FileReader();
                                fileReader.onload = function(event) {
                                    // noinspection JSUnresolvedVariable
                                    var arrayBuffer = event.target.result;
                                    audioContext.decodeAudioData(arrayBuffer).then(function(buffer) {
                                        console.log("translateLocalSpeechTTS.decodeAudioData", buffer);

                                        // Push the audio to the outgoing audio queue that will be played to the phone call.
                                        audioToCallQueue.push({
                                            audio: buffer,
                                            originalMessage: transcribedMessage,
                                            translatedMessage: translatedMessage
                                        });
                                    });
                                };
                                fileReader.readAsArrayBuffer(mp3Blob);

                            },
                            function(error) {
                                console.error("translateLocalSpeechTTS.requestAudioTTS error", error);
                            });
                    },
                    function(error) {
                        console.error("translateLocalSpeechTTS.translateText error", error);
                    });

            },
            function(error) {
                console.error("translateLocalSpeechTTS.transcribeSpeech error", error);
            });}*/
    

    /**
     * Transcribes, translates the specified speech textually using translationCfg object.
     *
     * @param speechWAV
     * @param successCB
     * @param errorCB
     */

    function translateRemoteSpeechText(speechWAV, successCB, errorCB) {
        console.log("translateRemoteSpeechText");
        //console.error(autoDetection);
        if(autoDetection==='remote'){
            //console.error('enviando mensajes al autodetector remote');
            detectlanguage(speechWAV, autoDetectionCount);
        }

        fetch('https://oje8wenrt0.execute-api.us-east-2.amazonaws.com/V1/stst/'+translationCfg.remoteLanguage+'/'+translationCfg.localLanguage,
            {
                method: 'POST', 
                body: speechWAV,
                headers:{
                    'Content-Type': 'audio/wav'
                }
            }
        )
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            //console.error(myJson);

            /*var Sound = (function () {
                var df = document.createDocumentFragment();
                return function Sound(src) {
                    var snd = new Audio(src);
                    df.appendChild(snd); // keep in fragment until finished playing
                    snd.addEventListener('ended', function () {df.removeChild(snd);});
                    snd.play();
                    return snd;
                }
            }());
            
            var snd = Sound("data:audio/wav;base64," + myJson.audio_base64);*/
            var logDiv = document.getElementById('log');

            if(LAYOUT==='transcript'){
                var today = new Date();
                var time = '['+today.getHours()+":"+today.getMinutes()+":"+today.getSeconds()+']';
                //text = time+" "+text;
                var speaker = time + '';
                if(myJson.translated_text!=='')
                    if(bilingualRemote){
                        logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message caller\'>'+myJson.source_text+'</span><p class=\'translation\'>'+findLanguage(translationCfg.localLanguage)+' '+myJson.translated_text+'</p></div>';
                    }
                    else{
                        logDiv.innerHTML += '<div class=\'message-container\'><span class=\'speaker\'>'+speaker+'</span><span class=\'message caller\'>'+myJson.source_text+'</span></div>';
                    }
            }
            else{
                if(myJson.translated_text!=='')
                    if(bilingualRemote){
                        logDiv.innerHTML += '<div class=\'message-container message-container-in\'><div class=\'message-overlay\'><div class=\'message message-in\'><p>'+myJson.source_text+'</p></div><p class=\'translation-out\'>'+findLanguage(translationCfg.localLanguage)+' '+myJson.translated_text+'</p></div></div>';
                    }
                    else{
                        logDiv.innerHTML += '<div class=\'message-container\'><div class=\'message message-in\'><p>'+myJson.source_text+'</p></div></div>';
                    }
            }
            logDiv.scrollTop = logDiv.scrollHeight;
            
        })
        .catch(error=>{
            console.error(error);
        });

    }
        /*TYWI.transcribeSpeech(
            speechWAV,
            getRemoteLanguageLong(),
            function(transcribedSpeechText) {
                console.log("translateRemoteSpeechText - transcribeSpeech successful: ", transcribedSpeechText);
                let transcribedMessage = transcribedSpeechText;
                TYWI.translateText(
                    transcribedSpeechText,
                    getRemoteLanguageShort(),
                    getLocalLanguageShort(),
                    function(translatedText) {
                        /*console.log("translateRemoteSpeechText - translateText successful: ", translatedText);
                        if (successCB) successCB(translatedText);*/
                        /*console.log("translateLocalSpeechTTS.translateText", translatedText);
                        let translatedMessage = translatedText;
                        TYWI.requestAudioTTS(
                            translatedText,
                            getLocalLanguageShort(),
                            translationCfg.ttsVoice,
                            function(mp3Blob) {
                                console.log("translateLocalSpeechTTS.requestAudioTTS", mp3Blob);
                                var fileReader = new FileReader();
                                fileReader.onload = function(event) {
                                    // noinspection JSUnresolvedVariable
                                    var arrayBuffer = event.target.result;
                                    audioContext.decodeAudioData(arrayBuffer).then(function(buffer) {
                                        console.log("translateLocalSpeechTTS.decodeAudioData", buffer);

                                        // Push the audio to the outgoing audio queue that will be played to the phone call.
                                        let aux=[];
                                        aux.push({
                                            audio: buffer, //maicol
                                            originalMessage: transcribedMessage,
                                            translatedMessage: translatedMessage
                                        });
                                        successCB(transcribedMessage,translatedMessage);
                                        //console.error(aux);
                                        remoteText={originalMessage:transcribedMessage, translatedMessage:translatedMessage}
                                    });
                                };
                                fileReader.readAsArrayBuffer(mp3Blob);

                            },
                            function(error) {
                                console.error("translateLocalSpeechTTS.requestAudioTTS error", error);
                            });
                            /*
                    },
                    function(error) {
                        console.error("translateRemoteSpeechText - translateText error: ", error);
                        if (errorCB) errorCB(error);
                    });

            },
            function(error) {
                console.error("translateRemoteSpeechText - transcribeSpeech error: ", error);
                if (errorCB) errorCB(error);
            });
    }*/

    /**
     * Processes the audio queue
     */
    function processAudioToCallQueue() {
        if (callInProgress) { // Is a call in progress?
            if (!audioToCallIsPlaying) { // Only playback if there is no audio currently playing
                if (audioToCallQueue && audioToCallQueue.length > 0) { // Are there anything to playback in the queue?
                    var nextAudioToPlay = audioToCallQueue.shift();
                    playbackAudioToCall(nextAudioToPlay);
                }
            }
            setTimeout(processAudioToCallQueue, processAudioToCallInterval);
        }
    }

    // noinspection JSUnusedLocalSymbols
    /**
     *
     * @returns {string}
     */
    function getLocalLanguageLong() {
        return translationCfg.localLanguage;
    }

    /**
     *
     * @returns {string}
     */
    function getLocalLanguageShort() {
        return translationCfg.localLanguage.split("-")[0];
    }

    /**
     *
     * @returns {string}
     */
    function getRemoteLanguageLong() {
        return translationCfg.remoteLanguage;
    }

    /**
     *
     * @returns {string}
     */
    function getRemoteLanguageShort() {
        return translationCfg.remoteLanguage.split("-")[0];
    }

    /**
     *
     * @param number
     * @returns {boolean}
     */
    function isAValidPhoneNumber(number) {
        return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(number);
    }

    function setTTSVoiceForRemoteLanguage() {
        var voiceId = getTTSVoiceIdForLanguage(translationCfg.remoteLanguage, translationCfg.ttsGender);

        console.log("setTTSVoiceForRemoteLanguage " + translationCfg.remoteLanguage + "/" + translationCfg.ttsGender + " -> " + voiceId);

        translationCfg.ttsVoice = voiceId;
    }

    /**
     *
     * @param languageCode
     * @param gender
     */
    function getTTSVoiceIdForLanguage(languageCode, gender) {
        languageCode = languageCode.toLowerCase();

        // Check if we have defined a voice for the language-dialect code.
        if (TTS[languageCode]) {
            return TTS[languageCode][gender].voice;
        }
        // If none is defined, check if we have a default for main language without dialect.
        else if (TTS[languageCode.split("-")[0]]) {
            console.warn("No voice found for language-dialect " + languageCode + " using default for main language instead voice: " + TTS[languageCode.split("-")[0]][gender].voice);
            return TTS[languageCode.split("-")[0]][gender].voice;
        } else {
            console.warn("No voice found for language " + languageCode + " using default male voice: " + TTS['en-us'].male.voice);
            // Default is en_us male
            return TTS['en-us'].male.voice;
        }
    }

    // Initialize the user interface.
    initGUI();

});

export { 
    getLanguagesDetected, 
    getTranslationParameters, 
    startAutoDetection, 
    stopAutoDetection,

    getCallStatus,
    setCallBack,
    setLocalLanguage,
    setRemoteLanguage,
    setBilingualRemote,
    detectlanguage, 
    setGender,
    translateRemoteSpeechText,
};
import React from 'react';

import './styles.scss';

const ProgressBar = (props) => {
    return (
        <div className='progress-bar'>
            <div className='progress' style={{width:(props.percentage+'%')}}></div>
        </div>
    );
}
export default ProgressBar;
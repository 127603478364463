import React from 'react';

import './flags.css';
import flags from '../../img/flags.png';

const Flag = (props) => {
    return (
        <div className={'flag flag-'+props.country} style={{backgroundImage:`url(${flags})`}}/>
    )
}
export default Flag;
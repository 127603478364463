import React,{Component} from 'react';

import './styles.scss';

export default class SwitchGender extends Component{
    
    state={
        value:'male',
    }
    
    componentDidMount(){

    }

    handeClick = (value) => {
        this.setState({value});
        let select = document.querySelector('#gender');
        select.addEventListener('change',function(){
            //console.log('changed');
        });
        //input.addEventListener('click',function(){
            select.value = this.state.value;
            select.dispatchEvent(new Event('change'));
            this.props.setGender(value);
        //});
    }

    render(){
        return (
            <div className='switch-gender'>
                <select id={this.props.id} value={this.props.default!==null?this.props.default:'male'} style={{display:'none'}}>
                    <option value="male" description="united_states">Male</option>
                    <option value="female" description="australia">Female</option>
                </select>
                <span className={'switch-gender-item'+(this.state.value==='male'?' active':'')} onClick={()=>{this.handeClick('male')}}>Male</span>
                <span className={'switch-gender-item'+(this.state.value==='female'?' active':'')} onClick={()=>{this.handeClick('female')}}>Female</span>
            </div>
        )
    }
}
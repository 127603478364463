import React,{Component} from 'react';

import LanguageSelect from '../../components/languageSelect';
import SwitchGender from '../../components/switchGender';
import Buttom from '../../components/button';
import LandingMessage from '../../components/landingMessage';

import './styles.scss';

export default class Dashboard extends Component{
    state={
        visible:true,
        count:0,
    }

    handleVisible = () => {
        //console.error('VISIBLE')
        if(this.state.count===1)
            this.setState({visible:false});
        this.setState({count:this.state.count+1});
    }

    render(){
        return (
            <div className='dashboard' style={{display:this.state.visible?'':'none'}}>
                <LandingMessage/>
                <div className='settings'>
                    <div>
                        <h3 htmlFor="gender">Do you need a voice of a</h3>
                        <SwitchGender
                            id={'gender'}
                            setGender = {(gender) => {this.props.setGender(gender)}}
                        />
                        <LanguageSelect
                            title='Local Language'
                            id='language_selection_local'
                        />
                        <LanguageSelect
                            title='Remote Language'
                            id='language_selection_remote'
                        />
                        <Buttom 
                            id='ready-button' 
                            style={{
                                color:'#fff',
                                backgroundColor:'#ed8e3c', 
                                border:'none', 
                                padding: '1em 1.5em 1em 1.5em',
                                marginTop: '1em',
                            }}
                            callBack={this.handleVisible}
                            onClick={()=>{this.props.upDateTranslationParameters()}}
                        >
                            START CLIENT
                        </Buttom>
                    </div>
                </div>
            </div>
        )
    }
}
import React,{useState} from 'react';

import './styles.scss';

const Notification = (props) => {
    
    //const [state, setState] = useState({visible:props.visible})

    var timer;

    const start = ()=> { timer = setTimeout(function(){ /*setState({visible:false});*/ props.setVisible(); }, 5000); };

    return (
        <div>
            {props.visible?start():''}
            <div className={'notification-container '+(props.visible?'visible':'hidden')} style={props.style}>
                <div className={'type '+(props.type==='warning'?'warning':props.type==='success'?'success':'error')}>
                    {
                        props.type==='warning'?
                            <i className="fas fa-exclamation"></i>
                        :(
                            props.type==='success'?
                                <i className="fas fa-check"></i>
                            :
                                <i className="fas fa-times"></i>
                        )
                    }
                </div>
                <div className={'content'}>
                    {props.message}
                </div>
                <div className='close-btn' onClick={()=>{props.setVisible(); clearInterval(timer);}}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
        </div>
    )
}
export default Notification;
import React from 'react';

import './styles.scss';

const Switch = (props) => {
    return (
        <div className='switch-container' style={{...props.style}} onClick={()=>{if(props.onClick!==undefined)props.onClick()}}>
            <span>{props.name}</span>
            <label className="switch l1">
                <input id={props.id} type="checkbox" defaultChecked={props.checked!==undefined?props.checked:true}/>
                <span className="slider round"></span>
            </label>
        </div>
    )
}
export default Switch;
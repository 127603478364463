
import { translateRemoteSpeechText, detectlanguage, setGender, getTranslationParameters, startAutoDetection, stopAutoDetection, setCallBack, getCallStatus, setLocalLanguage, setRemoteLanguage, setBilingualRemote } from './js/twiliospeechcapture.js';

import React,{Component} from 'react';
import FileDrop from 'react-file-drop';
import './css/app.scss';
import './css/normalize.css';
import './css/site.css';

import Dashboard from './containers/dashboard';

import CallControls from './components/CallControls';
import Chat from './components/chat';
import Notification from './components/processingAudio';

const languages=['aa,Afar',
        'ab,Abkhazian',
        'af,Afrikaans',
        'ak,Akan',
        'als,Alemannic',
        'am,Amharic',
        'an,Aragonese',
        'ang,Angal',
        'ang,Anglo-Saxon / Old English',
        'ar,Arabic',
        'arc,Aramaic',
        'as,Assamese',
        'ast,Asturian',
        'av,Avar',
        'awa,Awadhi',
        'ay,Aymara',
        'az,Azerbaijani',
        'ba,Bashkir',
        'bar,Bavarian',
        'bat-smg,Samogitian',
        'bcl,Bikol',
        'be,Belarusian',
        'be-x-old,Belarusian (Taraškievica)',
        'bg,Bulgarian',
        'bh,Bihari',
        'bi,Bislama',
        'bm,Bambara',
        'bn,Bengali',
        'bo,Tibetan',
        'bpy,Bishnupriya Manipuri',
        'br,Breton',
        'bs,Bosnian',
        'bug,Buginese',
        'bxr,Buriat (Russia)',
        'ca,Catalan',
        'cdo,Min Dong Chinese',
        'ce,Chechen',
        'ceb,Cebuano',
        'ch,Chamorro',
        'cho,Choctaw',
        'chr,Cherokee',
        'chy,Cheyenne',
        'co,Corsican',
        'cr,Cree',
        'cs,Czech',
        'csb,Kashubian',
        'cu,Old Church Slavonic / Old Bulgarian',
        'cv,Chuvash',
        'cy,Welsh',
        'da,Danish',
        'de,German',
        'diq,Dimli',
        'dsb,Lower Sorbian',
        'dv,Divehi',
        'dz,Dzongkha',
        'ee,Ewe',
        'el,Greek',
        'en,English',
        'eo,Esperanto',
        'es,Spanish',
        'et,Estonian',
        'eu,Basque',
        'ext,Extremaduran',
        'fa,Persian',
        'ff,Peul',
        'fi,Finnish',
        'fiu-vro,Võro',
        'fj,Fijian',
        'fo,Faroese',
        'fr,French',
        'frp,Arpitan / Franco-Provençal',
        'fur,Friulian',
        'fy,West Frisian',
        'ga,Irish',
        'gan,Gan Chinese',
        'gbm,Garhwali',
        'gd,Scottish Gaelic',
        'gil,Gilbertese',
        'gl,Galician',
        'gn,Guarani',
        'got,Gothic',
        'gu,Gujarati',
        'gv,Manx',
        'ha,Hausa',
        'hak,Hakka Chinese',
        'haw,Hawaiian',
        'he,Hebrew',
        'hi,Hindi',
        'ho,Hiri Motu',
        'hr,Croatian',
        'ht,Haitian',
        'hu,Hungarian',
        'hy,Armenian',
        'hz,Herero',
        'ia,Interlingua',
        'id,Indonesian',
        'ie,Interlingue',
        'ig,Igbo',
        'ii,Sichuan Yi',
        'ik,Inupiak',
        'ilo,Ilokano',
        'inh,Ingush',
        'io,Ido',
        'is,Icelandic',
        'it,Italian',
        'iu,Inuktitut',
        'ja,Japanese',
        'jbo,Lojban',
        'jv,Javanese',
        'ka,Georgian',
        'kg,Kongo',
        'ki,Kikuyu',
        'kj,Kuanyama',
        'kk,Kazakh',
        'kl,Greenlandic',
        'km,Cambodian',
        'kn,Kannada',
        'khw,Khowar',
        'ko,Korean',
        'kr,Kanuri',
        'ks,Kashmiri',
        'ksh,Ripuarian',
        'ku,Kurdish',
        'kv,Komi',
        'kw,Cornish',
        'ky,Kirghiz',
        'la,Latin',
        'lad,Ladino / Judeo-Spanish',
        'lan,Lango',
        'lb,Luxembourgish',
        'lg,Ganda',
        'li,Limburgian',
        'lij,Ligurian',
        'lmo,Lombard',
        'ln,Lingala',
        'lo,Laotian',
        'lzz,Laz',
        'lt,Lithuanian',
        'lv,Latvian',
        'map-bms,Banyumasan',
        'mg,Malagasy',
        'man,Mandarin',
        'mh,Marshallese',
        'mi,Maori',
        'min,Minangkabau',
        'mk,Macedonian',
        'ml,Malayalam',
        'mn,Mongolian',
        'mo,Moldovan',
        'mr,Marathi',
        'ms,Malay',
        'mt,Maltese',
        'mus,Creek / Muskogee',
        'mwl,Mirandese',
        'my,Burmese',
        'na,Nauruan',
        'nah,Nahuatl',
        'nap,Neapolitan',
        'nd,North Ndebele',
        'nds,Low German / Low Saxon',
        'nds-nl,Dutch Low Saxon',
        'ne,Nepali',
        'new,Newar',
        'ng,Ndonga',
        'nl,Dutch',
        'nn,Norwegian Nynorsk',
        'no,Norwegian',
        'nr,South Ndebele',
        'nso,Northern Sotho',
        'nrm,Norman',
        'nv,Navajo',
        'ny,Chichewa',
        'oc,Occitan',
        'oj,Ojibwa',
        'om,Oromo',
        'or,Oriya',
        'os,Ossetian / Ossetic',
        'pa,Panjabi / Punjabi',
        'pag,Pangasinan',
        'pam,Kapampangan',
        'pap,Papiamentu',
        'pdc,Pennsylvania German',
        'pi,Pali',
        'pih,Norfolk',
        'pl,Polish',
        'pms,Piedmontese',
        'ps,Pashto',
        'pt,Portuguese',
        'qu,Quechua',
        'rm,Raeto Romance',
        'rmy,Romani',
        'rn,Kirundi',
        'ro,Romanian',
        'roa-rup,Aromanian',
        'ru,Russian',
        'rw,Rwandi',
        'sa,Sanskrit',
        'sc,Sardinian',
        'scn,Sicilian',
        'sco,Scots',
        'sd,Sindhi',
        'se,Northern Sami',
        'sg,Sango',
        'sh,Serbo-Croatian',
        'si,Sinhalese',
        'simple,Simple English',
        'sk,Slovak',
        'sl,Slovenian',
        'sm,Samoan',
        'sn,Shona',
        'so,Somalia',
        'sq,Albanian',
        'sr,Serbian',
        'ss,Swati',
        'st,Southern Sotho',
        'su,Sundanese',
        'sv,Swedish',
        'sw,Swahili',
        'ta,Tamil',
        'te,Telugu',
        'tet,Tetum',
        'tg,Tajik',
        'th,Thai',
        'ti,Tigrinya',
        'tk,Turkmen',
        'tl,Tagalog',
        'tlh,Klingon',
        'tn,Tswana',
        'to,Tonga',
        'tpi,Tok Pisin',
        'tr,Turkish',
        'ts,Tsonga',
        'tt,Tatar',
        'tum,Tumbuka',
        'tw,Twi',
        'ty,Tahitian',
        'udm,Udmurt',
        'ug,Uyghur',
        'uk,Ukrainian',
        'ur,Urdu',
        'uz,Uzbek',
        've,Venda',
        'vi,Vietnamese',
        'vec,Venetian',
        'vls,West Flemish',
        'vo,Volapük',
        'wa,Walloon',
        'war,Waray / Samar-Leyte Visayan',
        'wo,Wolof',
        'xal,Kalmyk',
        'xh,Xhosa',
        'xmf,Megrelian',
        'yi,Yiddish',
        'yo,Yoruba',
        'za,Zhuang',
        'zh,Chinese',
        'zh-classical,Classical Chinese',
        'zh-min-nan,Minnan',
        'zh-yue,Cantonese',
        'zu,Zulu'];

var timer;
const start = (callback)=> { timer = setTimeout(function(){ callback(); }, 25000); };

var audio2;

export default class App extends Component{

    state = {
        languages:[],
        translationParameters:null,
        callStatus:{isCalling:false},
        onDrop:false,
        processingAudio:false,
        processingAudioText:'The audio is being processed',
        processingAudioType:'processing',
        processingAudioCountry:'',
    }

    handleProcessingAudio = () => {
        this.setState({ 
            processingAudio:false, 
            processingAudioText:'The audio is being processed',
            processingAudioType:'processing',
            processingAudioCountry:''
        });
    }

    findLanguage = (code) => {
        let auxCode = code.split('-');
        for(let i in languages){
            let aux = languages[i].split(',');
            if(aux[0]===auxCode[0])
                return aux[1];
        }
    }

    componentDidMount () {
        this.setState({translationParameters:getTranslationParameters(), callStatus:getCallStatus()}, ()=>{/*console.error(this.state.translationParameters)*/});
    }

    call  = () => {
        console.error("change");
    }

    updateTranslationParameters = () => {
        this.setState({translationParameters:getTranslationParameters()});
    }

    updateCallStatus = () => {
        this.setState({callStatus:getCallStatus()}, ()=>{/*console.error(this.state.callStatus)*/});
    }
    handleDrop = (files, event) => {
        //console.error('archivo',files[0]);
        audio2 = new Blob([files[0]], {type: files[0].type});
        this.setState({
            onDrop:false, 
            processingAudio:true,
            processingAudioText:'The audio is being processed',
            processingAudioType:'processing',
            processingAudioCountry:''
        });

        if(true){
            detectlanguage(audio2, this.call);
            translateRemoteSpeechText(audio2, this.state.translationParameters.localLanguage, this.state.translationParameters.remoteLanguage, ()=>{})
            //console.error(files[0]);
            try {
                var reader = new FileReader();
                reader.onload = function(evt) {
                    var audio = document.createElement("AUDIO2");
                    audio.controls = true;
                    // noinspection JSUnresolvedVariable
                    audio.src = evt.target.result;
                    audio.type = "audio/wav";
                    document.getElementById("localAudioLog").appendChild(audio);
                };
                reader.readAsDataURL(audio2);
                //console.error(audio2);
            } catch (ex) {
                console.error("appendWAVAudioBufferRemote ex: " + ex);
            }
        }
    }

    call = (parameter) => {
        start(this.handleProcessingAudio);
        //console.error('parameter',''+parameter==='Internal Server Error');
        if(''+parameter==='Internal Server Error')
            //alert('No language could be recognized');.
            this.setState({processingAudioText:'No language could be recognized',processingAudioType:'error'});
        else{
            //this.findLanguage(parameter.sourceLanguage);
            //alert(this.findLanguage(parameter.sourceLanguage));
            this.setState({
                processingAudioText:'Recognized language '+this.findLanguage(parameter.sourceLanguage), 
                processingAudioType:'recognized',
                processingAudioCountry:parameter.sourceLanguage,
            });
        }
    }

    render(){
        return (
            <div className="App">
                <div id="controls">
                    <div id="info" className='info'>

                        <Dashboard
                            upDateTranslationParameters = { this.updateTranslationParameters }
                            setGender = { setGender }
                        />

                        <div style={{display:'flex'}} onDragEnter={()=>{this.setState({onDrop:true});}}>
                            <CallControls
                                id='phone-number'
                                actionButton='button-call'
                                setCallBack={ setCallBack }
                                updateCallStatus = { this.updateCallStatus }
                                setBilingualRemote = {setBilingualRemote}
                            />
                            <Chat
                                startAutoDetection = { startAutoDetection }
                                stopAutoDetection = { stopAutoDetection }
                                call={this.call}
                                translationParameters = { this.state.translationParameters }
                                upDateTranslationParameters = { this.updateTranslationParameters }
                                callStatus = { this.state.callStatus.isCalling }
                                updateCallStatus = { this.updateCallStatus }
                                setLocalLanguage = { setLocalLanguage }
                                setRemoteLanguage = { setRemoteLanguage }
                                setGender = { setGender }
                            />
                        </div>
                        <div className={'file-drop'+(this.state.onDrop?' file-drop-visible':'')} onMouseLeave={()=>{this.setState({onDrop:false});}} onMouseUp={()=>{this.setState({onDrop:false});}}>
                            <FileDrop onDrop={this.handleDrop}>
                                Drop files here!
                            </FileDrop>
                        </div>
                        <Notification
                            message={this.state.processingAudioText}
                            type={this.state.processingAudioType}
                            visible={this.state.processingAudio}
                            setVisible={this.handleProcessingAudio}
                            country={this.state.processingAudioCountry}
                            setVisible={this.handleProcessingAudio}
                            translationParameters = { this.state.translationParameters }
                            upDateTranslationParameters = { this.updateTranslationParameters }
                        />
                    </div>
                </div>
            
                <div id="audioLog" style={{display:'none'}}>
                    Remote Audio Log
                </div>

                <div id="localAudioLog" style={{display:'none'}}>
                    Local Audio Log
                </div>
            </div>
        )
    }
}
